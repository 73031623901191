import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from "./MapPage.module.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Tab, Tabs, Typography, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",

      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fa6f32",

      contrastText: "#ffffff",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          // flexDirection: "column",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {},
        scrollButtons: {
          "&:last-of-type": {
            backgroundColor: "white !important",
            boxShadow: "0px 10px 17px -11px rgba(0,0,0,0.75)",
            position: "absolute",
            right: "0",
            zIndex: "999",
            height: "50%",
            top: "25%",
            borderRadius: "360px",

            color: "white",
            "&:hover": {
              backgroundColor: "green",
              color: "white",
            },
          },
          "&:first-of-type": {
            backgroundColor: "white !important",
            boxShadow: "0px 10px 17px -11px rgba(0,0,0,0.75)",
            position: "absolute",
            height: "50%",
            top: "25%",
            zIndex: "999",
            borderRadius: "360px",

            color: "white",
            "&:hover": {
              backgroundColor: "green",
              color: "white",
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "black !important",
          fontSize: "32px",
        },
      },
    },
  },
});

interface TruckData {
  imgSrc: string;
  headline: string;
}

interface MapPageSelectorProps {
  selectedPrecategory: string;
  allTruckData: TruckData[];
  selectedItem: string;
  handlePrecategory: (category: string) => void;
  handleItemClick: (category: string) => void;
  getSubCatTrucks: (subCategory: string) => void;
  getCatTrucks: (subCategory: string) => void;
  isFilterReset: boolean;
}

const MapPageSelector: FunctionComponent<MapPageSelectorProps> = ({
  selectedPrecategory,
  handlePrecategory,
  allTruckData,
  selectedItem,
  handleItemClick,
  getSubCatTrucks,
  getCatTrucks,
  isFilterReset,
}) => {
  const [value, setValue] = useState<number | null>(null);
  const [value2, setValue2] = useState<number | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isFilterReset == true) {
      console.log(isFilterReset);
      setValue(null);
      setValue2(null);
    }
  }, [isFilterReset]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    setValue(newValue);
  };

  const handleChange2 = (
    event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    setValue2(newValue);
  };

  const tabIndicatorRef = useRef(null);
  const gradient = useRef(null);

  return (
    <ThemeProvider theme={theme}>
      <Navbar
        expand="lg"
        style={{
          background: "white !important",
          width: "84%",
          display: "flex",
          flexDirection: "row",
          padding: "0",
        }}
      >
        <>
          <Container style={{ flexFlow: "nowrap" }}>
            <div id="containerLinks" style={{ display: "flex", gap: "2vw" }}>
              {/* We might need this */}

              {/* <Nav.Link
                href="#"
                style={{ textAlign: "center" }}
                onClick={() => handlePrecategory("17")}
                className={`hoverButtons ${
                  selectedPrecategory == "17" ? styles.choice : ""
                }`}
              >
                <p>
                  <img
                    className={styles.image75Icon1}
                    alt=""
                    src="./truck_types/17.svg"
                  />
                </p>
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Truck
                </Typography>
                {selectedPrecategory == "17" && (
                  <div className={styles.bottomBorder3} />
                )}
              </Nav.Link>

              <Nav.Link
                href="#"
                style={{ textAlign: "center" }}
                onClick={() => handlePrecategory("Trailer")}
                className={`hoverButtons ${
                  selectedPrecategory == "Trailer" ? styles.choice : ""
                }`}
              >
                <p>
                  <img
                    className={styles.image75Icon1}
                    alt=""
                    src="./truck_types/Trailer.svg"
                  />
                </p>
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Trailer
                </Typography>
                {selectedPrecategory == "Trailer" && (
                  <div className={styles.bottomBorder3} />
                )}
              </Nav.Link> */}
              <Box
                sx={{
                  flexGrow: 1,

                  bgcolor: "background.paper",
                  width: "inherit",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Tab
                    className={styles.truckListChoices}
                    style={{ maxHeight: "0" }}
                    // onClick={() => getCatTrucks("truck")}
                    icon={
                      <img
                        className={styles.image75Icon1}
                        src="./truck_types/17.svg"
                        alt="Truck Icon"
                      />
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      >
                        Truck
                      </div>
                    }
                  />
                </Button>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Tab
                    className={styles.truckListChoices}
                    style={{ maxHeight: "0" }}
                    // onClick={() => getCatTrucks("trailer")}
                    icon={
                      <img
                        className={styles.image75Icon1}
                        src="./truck_types/Trailer.svg"
                        alt="Truck Icon"
                      />
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      >
                        Trailer
                      </div>
                    }
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {allTruckData.map((truck: any, index: number) => (
                    <MenuItem
                      key={index}
                      style={{ gap: "2%" }}
                      // className={styles.truckListChoices}
                      onClick={() => {
                        getSubCatTrucks(truck.headline);
                        handleClose();
                      }}
                    >
                      <img
                        className={styles.image75Icon1}
                        src={truck.imgSrc}
                        alt="Truck Icon"
                      />

                      <span>{truck.headline}</span>
                    </MenuItem>
                  ))}
                </Menu>

                {/* First variant tabs */}

                {/* <Tabs
                  value={value2}
                  onChange={handleChange2}
                  // variant="scrollable"
                  aria-label="scrollable auto tabs example"
                  indicatorColor="secondary"
                  textColor="primary"
                >
                  <Tab
                    className={styles.truckListChoices}
                    style={{ maxHeight: "0" }}
                    onClick={() => getCatTrucks("truck")}
                    icon={
                      <img
                        className={styles.image75Icon1}
                        src="./truck_types/17.svg"
                        alt="Truck Icon"
                      />
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      >
                        Truck
                      </div>
                    }
                  />

                  <Tab
                    className={styles.truckListChoices}
                    style={{ maxHeight: "0" }}
                    onClick={() => getCatTrucks("trailer")}
                    icon={
                      <img
                        className={styles.image75Icon1}
                        src="./truck_types/Trailer.svg"
                        alt="Truck Icon"
                      />
                    }
                    label={
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                      >
                        Trailer
                      </div>
                    }
                  ></Tab>
                </Tabs> */}
              </Box>
            </div>

            <div className={styles.verticalDivider}></div>

            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={{ position: "absolute", right: "-1px", zIndex: 10 }}
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{ overflow: "auto", width: "auto" }}
            >
              <Nav className="me-auto" style={{ width: "100%" }}>
                <Box
                  sx={{
                    flexGrow: 1,

                    bgcolor: "background.paper",
                    width: "inherit",
                  }}
                >
                  <Tabs
                    ref={tabIndicatorRef}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    aria-label="scrollable auto tabs example"
                    indicatorColor="secondary"
                    textColor="primary"
                  >
                    {allTruckData.map((truck: any, index: number) => (
                      <Tab
                        key={index}
                        className={styles.truckListChoices}
                        style={{ maxHeight: "0" }}
                        onClick={() => getSubCatTrucks(truck.headline)}
                        icon={
                          <img
                            className={styles.image75Icon1}
                            src={truck.imgSrc}
                            alt="Truck Icon"
                          />
                        }
                        label={
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                            }}
                          >
                            {truck.headline}
                          </div>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
                <img
                  ref={gradient}
                  style={{
                    position: "absolute",
                    right: "-20px",
                    zIndex: "0",
                    pointerEvents: "none",
                  }}
                  src="./gradient.png"
                />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </>
      </Navbar>
    </ThemeProvider>
  );
};

export default MapPageSelector;
