import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import "./App.css";
import RegistrationPage01 from "./pages/RegistrationPage01.tsx";
import RegistrationInitialPage from "./pages/RegistrationInitialPage.tsx";
import RegistrationInitialOwner from "./pages/RegistrationInitialOwner.tsx";
import RegistrationOwnerFirst from "./pages/RegistrationOwnerFirst.tsx";
import RegistrationInitialRenter from "./pages/RegistrationInitialRenter.tsx";
import ProductPage from "./pages/Selected_Truck/ProductPage.tsx";
import MapPage from "./pages/Map_Page/MapPage.tsx";
import MapPageNavbar from "./pages/Map_Page/MapPageNavbar.tsx";
import AddTruckInitial from "./pages/Add_Truck/AddTruckInitial.tsx";
import OrganizationPage from "./pages/Organization_Page/OrganizationPage";
import UserAdministration from "./pages/Admin/UserAdministration.tsx";
import TruckManagement from "./pages/Admin/TruckManagement.tsx";
import Login from "./components/Login/Login.tsx";
import ForgotPassword from "./components/Login/ForgotPassword.tsx";
import MainAdministration from "./pages/Admin/MainAdministration.tsx";
import NotFoundPage from "./pages/NotFound/NotFound.tsx";
import ProtectedRoute from "./components/Route/ProtectedRoute.tsx";
import NoAuthRestrictedRoute from "./components/Route/NoAuthRestrictedRoute.tsx";
import Reservations from "./pages/Admin/ads/Reservations.tsx";
import Profile from "./pages/Admin/settings/Profile.tsx";
import store from "./store/store";
import Toast from "./components/Toast/Toast.tsx";
import Footer from "./pages/Map_Page/Footer.tsx";

function App() {
  return (
    <Provider store={store}>
      <Toast />
      <Router>
        {/* <MapPageNavbar />  ????*/}
        <div
          className="App"
          style={{ overflowY: "hidden", backgroundColor: "#fff" }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/main" />} />
            {/* <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} /> */}
            {/* <Route
              path="/registration_driver"
              element={<RegistrationPage01 />}
            />
            <Route
              path="/registration_driver/sign_up"
              element={<RegistrationInitialPage />}
            />
            <Route
              path="/registration_owner"
              element={<RegistrationInitialOwner />}
            />
            <Route
              path="/registration_owner/sign_up"
              element={<RegistrationOwnerFirst />}
            /> */}
            <Route
              path="/login"
              element={<NoAuthRestrictedRoute cmp={Login} />}
            />
            <Route
              path="/forgot_password"
              element={<NoAuthRestrictedRoute cmp={ForgotPassword} />}
            />
            {/* <Route
              path="/registration_driver"
              element={<NoAuthRestrictedRoute cmp={RegistrationPage01} />}
            /> */}
            <Route
              path="/registration_driver"
              element={
                <NoAuthRestrictedRoute cmp={RegistrationInitialRenter} />
              }
            />
            <Route
              path="/registration_driver/sign_up"
              element={<NoAuthRestrictedRoute cmp={RegistrationInitialPage} />}
            />
            <Route
              path="/registration_owner"
              element={<NoAuthRestrictedRoute cmp={RegistrationInitialOwner} />}
            />
            <Route
              path="/registration_owner/sign_up"
              element={<NoAuthRestrictedRoute cmp={RegistrationOwnerFirst} />}
            />
            <Route
              path="/main"
              element={
                <>
                  <MapPageNavbar />
                  <MapPage />
                  <Footer />
                </>
              }
            />
            <Route
              path="/truck/:id"
              element={
                <>
                  <MapPageNavbar />
                  <ProductPage />
                </>
              }
            />
            <Route
              path="/organization/:id"
              element={
                <>
                  <MapPageNavbar />
                  <OrganizationPage />
                </>
              }
            />
            <Route path="*" element={<NotFoundPage />} />

            {/* PROTECTED ROUTES */}
            <Route
              path="/add_truck"
              element={<ProtectedRoute cmp={AddTruckInitial} />}
            />
            <Route
              path="/admin/user_administration"
              element={<ProtectedRoute cmp={UserAdministration} />}
            />
            <Route
              path="/admin"
              element={<ProtectedRoute cmp={MainAdministration} />}
            />
            <Route
              path="/admin/truck_management"
              element={<ProtectedRoute cmp={TruckManagement} />}
            />
            <Route
              path="/admin/ads/reservations"
              element={<ProtectedRoute cmp={Reservations} />}
            />
            <Route
              path="/admin/settings/profile"
              element={<ProtectedRoute cmp={Profile} />}
            />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
