import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../Map_Page/MapPage.module.css";
import AdminNavBarLeadingContent from "./AdminNavBarLeadingContent";

const AdminNavBar: React.FC = () => {
  const handleLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid={true}>
          <Link to="/">
            <img
              className={styles.theLogoMakerBuiltFor1000}
              alt=""
              src="/generics//Royban.png"
              onKeyDown={() => {}}
              role="button"
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-2">
              <AdminNavBarLeadingContent />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavBar;
