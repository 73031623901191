import React, { ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { validateCurrentUser } from "./CheckAuth";
import Loader from "../Loader/Loader";
import { errorToast } from "../Toast/ToastMessage";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
  cmp: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  cmp,
}: ProtectedRouteProps): ReactElement => {
  const Cmp = cmp;
  const reduxState = useSelector((state: any) => state);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const currentUserUpdated = localStorage.getItem('currentUserUpdated');
    if(reduxState?.currentUser.role !== '' && (accessToken && refreshToken) && !currentUserUpdated){
      setLoading(false);
      return;
    }

    if (accessToken && refreshToken) {
      validateCurrentUser(accessToken, refreshToken)
        .then((validUser) => {
          if (validUser) {
            setLoading(false);
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Error validating user:", error);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          errorToast("User session expired")
          navigate("/login");
        });
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/login");
    }
    localStorage.removeItem("currentUserUpdated");
  }, [navigate]);

  return (
    <div>
      {/* <Topnav /> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Cmp />
        </>
      )}
    </div>
  );
};

export default ProtectedRoute;
