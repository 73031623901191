import React, { useEffect, useState } from "react";
import styles from "../../css/Registration_Driver/AddOrganizationList.module.css";
import styles2 from "../../css/Registration_Driver/AddOrganizationDetails2.module.css";
import styled from "styled-components";
import { RegistrationData, RootState } from "../../store/types";
import { updateRegistrationData } from "../../store/actions/registrationActions";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { errorToast } from "../Toast/ToastMessage";
import { PhoneInput } from "react-international-phone";
import { setOrganization } from "../../store/actions/organizationAction";
import { getNoAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_ORG_API_BASE_URL, REACT_APP_UPLOAD_API_BASE_URL } from '../../utils/UrlConstants';

type VechicleToRentProps = {
  onNextButtonClick: () => void;
  onSkipButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onChangeRouteNextButtonClick?: () => void;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
  role: string;
  registerData?: RegistrationData;
  onClick?: () => void;
};

interface Company {
  name: string;
  physicalAddress?: {
    street: string;
  };
}

const mapDispatchToProps = {
  updateRegistrationData,
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const connectorVechicleToRent = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromReduxVechicleToRent = ConnectedProps<
  typeof connectorVechicleToRent
>;

const AddOrganizationList: React.FC<
  VechicleToRentProps & PropsFromReduxVechicleToRent
> = ({
  onSkipButtonClick,
  onBackButtonClick,
  updateRegistrationData,
  registrationData,
}) => {
  const dispatch = useDispatch();
  const userRegistrationState = useSelector((state: RootState) => state.registrationData);
  const [requiredField, setRequiredField] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>(userRegistrationState?.organizations[0]?.name || "");
  const [newCompanyPhone, setNewCompanyPhone] = useState<string>("");
  const [email, setEmail] = useState<string>(userRegistrationState?.email);
  const [address, setAddress] = useState<string>("");
  const [newCompanyDot, setNewCompanyDot] = useState<string>("");
  const [dataCompaniesOriginal, setDataCompaniesOriginal] = useState<Company[]>([]);
  const [dataCompanies, setDataCompanies] = useState<Company[] | any>(dataCompaniesOriginal);
  const [showOriginalContent, setShowOriginalContent] = useState<boolean>(true);

  useEffect(() => {
    let isInitialRender = true;

    fetchData("");

    return () => {
      isInitialRender = false;
    };
  }, []);

  const handleCompanyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    registrationData = {
      ...registrationData,
      email: newEmail,
    };
    updateRegistrationData({
      ...registrationData,
      email: newEmail,
    });
  };

  const handlePhoneNumber = (phone: string) => {
    const numericValue = phone.replace(/[^\d+]/g, ""); 
    setNewCompanyPhone(numericValue);
  };

  const handleNewCompanyDot = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCompanyDot(e.target.value);

    const updatedOrganizations = [...registrationData.organizations];

    updatedOrganizations[0] = {
      ...updatedOrganizations[0],
      dotNumber: e.target.value,
    };
    updateRegistrationData({
      ...registrationData,
      organizations: updatedOrganizations,
    });
  };

  const handleCompanyNameChange = (
    input: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    let inputValue: string;
    if (typeof input === "string") {
      inputValue = input;
    } else {
      inputValue = input.target.value;
    }
    setCompanyName(inputValue);
    fetchData(inputValue);

    if (inputValue === "") {
      setDataCompanies(dataCompaniesOriginal);
    } else {
      const filteredCompanies = dataCompaniesOriginal.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      setDataCompanies(filteredCompanies);
    }
    const updatedOrganizations = [...userRegistrationState.organizations];

    updatedOrganizations[0] = {
      ...updatedOrganizations[0],
      name: inputValue,
    };
    updateRegistrationData({
      ...userRegistrationState,
      organizations: updatedOrganizations,
    });
  };

  const handleAccordionClick = async (
    name: string,
    address: string,
    companyEmail: string,
    phone: string,
    dot: string,
    item: any
  ) => {
    setCompanyName(name);
    setEmail(companyEmail);
    const updatedOrganizations = [
      {
        ...userRegistrationState.organizations[0],
        name: name,
        dotNumber: dot,
      },
    ];
    const result = await createOrganization(item);
    if (result) {
      setOrganization({});

      dispatch(setOrganization({ phone: phone }));

      updateRegistrationData({
        ...registrationData,
        organizations: updatedOrganizations,
        email: companyEmail,
      });

      onSkipButtonClick();
    }
  };

  const handleNextButtonClick = async (step?: number) => {
    if (!companyName) {
      const emptyField = !companyName ? "companyName" : "";
      setRequiredField(emptyField);
      return;
    }

    const requestBody = {
      name: companyName,
      physicalAddress: {
        street: address,
        city: address,
        state: address,
        zip: "123",
        country: address,
      },
      mailingAddress: {
        street: address,
        city: address,
        state: address,
        zip: "123",
        country: address,
      },
      email: email,
      phone: newCompanyPhone,
      dotNumber: newCompanyDot,
    };
    const result = await createOrganization(requestBody);
    if (result) {
      onSkipButtonClick();
    }
  };

  const createOrganization = async (requestBody: any) => {
    const apiUrl = `${REACT_APP_ORG_API_BASE_URL}/organization/create`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getNoAuthHeaders(),
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.error || "Please select a valid organization";
        errorToast(errorMessage);
        setCompanyName("");
        return false;
      }

      return data;
    } catch (error) {
      errorToast(`${error}`);
      throw error;
    }
  };

  const handleAddItClick = () => {
    setShowOriginalContent(false);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const fetchData = async (search: string) => {
    try {
      const apiUrl = `${
        REACT_APP_UPLOAD_API_BASE_URL
      }/upload/files/fmcsa/search?search=${search || companyName}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: getNoAuthHeaders(),
      });

      if (!response.ok) {
        console.error("failed", JSON.stringify(response));
        return;
      }

      const data = await response.json();
      setDataCompaniesOriginal(Array.from(data));
      setDataCompanies(Array.from(data));
      console.log(data);

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const originalContent = (
    <div className={styles.backButtonParent}>
      <div
        className={styles.textButton}
        onClick={handleBackButtonClick}
        style={{ cursor: "pointer" }}
      >
        <img className={styles.icon} alt="" src="/generics/icon5.svg" />
        <div className={styles.reefer}>Back</div>
      </div>
      <div className={styles.stepcircleWithTextParent}>
        <div className={styles.stepcircleWithText}>
          <div className={styles.stepcircle}>
            <img className={styles.icon} alt="" src="/generics/check1.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Sign up</div>
          </div>
        </div>
        <div className={styles.instanceChild} />
        <div className={styles.stepcircleWithText1}>
          <div className={styles.stepcircle1}>
            <img className={styles.icon} alt="" src="/generics/check.svg" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Company details</div>
          </div>
        </div>
        <div className={styles.instanceChild} />
        <div className={styles.stepcircleWithText2}>
          <div className={styles.stepcircle2}>
            <div className={styles.dot} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Verification</div>
          </div>
        </div>
      </div>
      <div className={styles.userNameParent}>
        <div className={styles.userName}>Your organization details</div>
        <div
          className={styles.inputText3}
        >Please provide your Organization information accurately, it will be used in all your communications on the platform.&nbsp;</div>
      </div>
      <div className={styles.textButtonGroup}>
        <div className={styles.textButton1}>
          <img className={styles.icon} alt="" src="/generics/icon6.svg" />
          <div className={styles.reefer}>Back&nbsp;</div>
        </div>
        <div className={styles.inputWithLabelParent}>
          <div className={styles.inputWithLabel}>
            <div className={styles.reefer}>Company name&nbsp;</div>
            <div
              className={styles.basicInput}
              style={
                requiredField === "companyName"
                  ? { border: "1px solid red" }
                  : {}
              }
            >
              <input
                type="text"
                className={styles.inputField}
                value={companyName}
                placeholder="Company name"
                style={{ border: "none", paddingLeft: "0" }}
                onChange={handleCompanyNameChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.frameChild} />

        <div className={styles.inputTextContainer}>
          <div className={styles.inputText8}>Length</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Small</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Large</div>
            </div>
          </div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText8}>Lift Gate</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Rail Gate</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Tuck Under</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>No Lift Gate</div>
            </div>
          </div>
        </div>
        <div className={styles.resultsFoundParent}>
          <div className={styles.resultsFound}>
            {dataCompanies.length} results found
          </div>
          <div
            className={styles.companyNotOnTheListParent}
            onKeyDown={() => {}}
            role="button"
            onClick={handleAddItClick}
          >
            <div className={styles.resultsFound}>Company not on the list?</div>
            <div className={styles.addIt}>+Add it</div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.accordionParent} id={"listItems"}>

            {dataCompanies.map((item: any) => {
              return (
                <div
                  key={item}
                  className={styles.accordion}
                  role="button"
                  onClick={() =>
                    handleAccordionClick(
                      item.name,
                      item.physicalAddress?.street,
                      item.email,
                      item.phone,
                      item.dotNumber,
                      item
                    )
                  }
                >
                  <div className={styles.headlineParent}>
                    <div className={styles.headline}>{item.name}</div>
                  </div>
                  <div
                    className={styles.inputText1}
                    style={{ textAlign: "left" }}
                  >
                    {item.physicalAddress?.street}
                    {", "}
                    {item.physicalAddress.state}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={`${styles.button2} hoverButtons`}>
          <div className={styles.reefer}>
            <span>Company not on the list?&nbsp;</span>
            <span
              className={styles.addIt1}
              onKeyDown={() => {}}
              role="button"
              onClick={handleAddItClick}
            >
              +Add it
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const alternateContent = (
    <div className={styles2.lookingForRent05}>
      <div className={styles2.celesClubPVidiGruzovikhMaParent}>
        <img
          className={styles2.celesClubPVidiGruzovikhMaIcon}
          alt=""
          src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
        />
        <img
          className={styles2.stockPhotoBigRigGrayBonneIcon}
          alt=""
          src="/generics/stockphotobigriggraybonnetsemitruckwithhighcabandlongrefrigeratorsemitrailertransporting2263359175removebgpreview@2x.png"
        />
        <div className={styles2.inputTextParent}>
          <div className={styles2.inputText}>
            Hire internationally with complete confidence
          </div>
          <div className={styles2.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
      </div>

      <div className={styles2.lookingForRent05Child} />
      <div className={styles2.backButtonParent}>
        <div
          className={styles2.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles2.icon} alt="" src="/generics/icon5.svg" />
          <div className={styles2.reefer}>Back</div>
        </div>
        <div className={styles2.stepcircleWithTextParent}>
          <div className={styles2.stepcircleWithText}>
            <div className={styles2.stepcircle}>
              <img className={styles2.icon} alt="" src="/generics/check1.svg" />
            </div>
            <div className={styles2.content}>
              <div className={styles2.title}>Sign up</div>
            </div>
          </div>
          <div className={styles2.instanceChild} />
          <div className={styles2.stepcircleWithText1}>
            <div className={styles2.stepcircle1}>
              <img className={styles2.icon} alt="" src="/generics/check.svg" />
            </div>
            <div className={styles2.content}>
              <div className={styles2.title}>Company details</div>
            </div>
          </div>
          <div className={styles2.instanceChild} />
          <div className={styles2.stepcircleWithText2}>
            <div className={styles2.stepcircle2}>
              <div className={styles2.dot} />
            </div>
            <div className={styles2.content}>
              <div className={styles2.title}>Verification</div>
            </div>
          </div>
        </div>
        <div className={styles2.userNameParent}>
          <div className={styles2.userName}>Your organization details</div>
          <div
            className={styles2.inputText3}
            style={{ textAlign: "left" }}
          >Please provide your Organization information accurately, it will be used in all your communications on the platform. &nbsp;</div>
        </div>
        <div className={styles2.textButtonGroup}>
          <div className={styles2.textButton1}>
            <img className={styles2.icon} alt="" src="/generics/icon6.svg" />
            <div className={styles2.reefer}>Back &nbsp;</div>
          </div>
          <div className={styles2.frameParent}>
            <div className={styles2.inputWithLabelParent}>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>State&nbsp;</div>
                <div className={styles2.basicInput2}>
                  <input
                    type="text"
                    className={styles2.inputField}
                    placeholder="Statequarters"
                    style={{ border: "none" }}
                  />
                </div>
              </div>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>Company Name</div>
                <div className={styles2.basicInput2}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={companyName}
                    placeholder="Company name"
                    style={{ border: "none" }}
                    onChange={handleCompanyNameChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles2.inputWithLabelParent}>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>Your company e-mail</div>
                <div className={styles2.basicInput2}>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Your company e-mail"
                    style={{ border: "none" }}
                    onChange={handleCompanyEmail}
                  />
                </div>
              </div>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>Company phone&nbsp;</div>
                <div className={styles2.basicInput2}>
                  <PhoneInput
                    style={
                      requiredField === "phone"
                        ? { border: "1px solid red" }
                        : { border: "", borderRadius: "5px" }
                    }
                    className={styles.phoneNumberInput}
                    defaultCountry="US"
                    value={newCompanyPhone}
                    onChange={(phone: any) => {
                      handlePhoneNumber(phone);
                    }}
                    inputStyle={{
                      width: "80%",
                      fontSize: "16px",
                      color: "#64748b",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles2.inputWithLabelParent}>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>Address</div>
                <div className={styles2.basicInput2}>
                  <input
                    type="text"
                    className={styles2.inputField}
                    value={address}
                    placeholder="123 Main Street, Anytown, CA, 12345"
                    style={{ border: "none" }}
                    onChange={handleAddressChange}
                  />
                </div>
              </div>
              <div className={styles2.inputWithLabel}>
                <div className={styles2.reefer}>DOT Number&nbsp;</div>
                <div className={styles2.basicInput2}>
                  <input
                    type="text"
                    className={styles2.inputField}
                    placeholder="0000000"
                    value={
                      isNaN(Number(newCompanyDot))
                        ? ""
                        : newCompanyDot
                    }
                    maxLength={7}
                    style={{ border: "none" }}
                    onChange={handleNewCompanyDot}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles2.frameChild} />
          <div className={`${styles2.button1} hoverButtons`}>
            <div
              className={styles2.text}
              onKeyDown={() => {}}
              role="button"
              onClick={() => handleNextButtonClick(6)}
            >
              Next
            </div>
          </div>
          <div className={styles2.inputTextParent1}>
            <div className={styles2.inputText15}>Length</div>
            <div className={styles2.tagParent}>
              <div className={styles2.tag}>
                <img
                  className={styles2.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles2.reefer}>Small</div>
              </div>
              <div className={styles2.tag}>
                <img
                  className={styles2.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles2.reefer}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles2.inputTextParent1}>
            <div className={styles2.inputText15}>Lift Gate</div>
            <div className={styles2.tagParent}>
              <div className={styles2.tag}>
                <img
                  className={styles2.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles2.reefer}>Rail Gate</div>
              </div>
              <div className={styles2.tag}>
                <img
                  className={styles2.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles2.reefer}>Tuck Under</div>
              </div>
              <div className={styles2.tag}>
                <img
                  className={styles2.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles2.reefer}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <>{showOriginalContent ? originalContent : alternateContent}</>;
};

export default connectorVechicleToRent(AddOrganizationList);

const StyledButton = styled.button`
  position: absolute;
  border: 0;
  right: 0;
  background-color: transparent;
`;

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const SelectedValue = styled.div`
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--brand-100, #fedbcc);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
