import React, { useState, useEffect } from "react";
import styles from "../../css/Registration_Driver/LookingForRentFinal.module.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { REACT_APP_SHORT_COUNTDOWN } from '../../utils/UrlConstants';

type VechicleToRentProps = {
  onNextButtonClick?: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  role: string;
  onClick?: () => void;
};

const AddOrganizationFinal: React.FC<VechicleToRentProps> = ({
  onNextButtonClick,
  onBackButtonClick,
  role,
  onNextButtonKeyDown,
  ...props
}) => {
  const [countdown, setCountdown] = useState<number>(parseInt(REACT_APP_SHORT_COUNTDOWN || '5'));
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate("/main");
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, navigate]);

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <>
      <div className={styles.lookingForRent08}>
        <div className={styles.lookingForRent08Child} />
        <div className={styles.modal}>
          <div
            className={styles.textButton}
            onClick={handleBackButtonClick}
            style={{ cursor: "pointer" }}
          >
            <img className={styles.icon} alt="" src="/generics/icon2.svg" />
            <div className={styles.reefer}>Back&nbsp;</div>
          </div>
          <div className={styles.iconParent}>
            <img
              className={styles.icon1}
              alt=""
              src="/generics/iconsolidcheckcircle.svg"
            />
            <div className={styles.userName}>
              Thank you for successfully registering on our website!
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText3}>
              We are glad to welcome you to our community.
            </div>
            <div className={styles.inputText3}>
              Redirecting in {countdown} sec
            </div>
          </div>
          <div className={`${styles.button1} hoverButtons`}>
            <Link to="/main">
              <div className={styles.text} style={{ color: "white" }}>
                Explore the sites
              </div>
            </Link>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.inputField}>Length</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.frameDiv}>
            <div className={styles.inputField}>Lift Gate</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Rail Gate</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Tuck Under</div>
              </div>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrganizationFinal;
