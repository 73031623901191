import { useSelector } from "react-redux";
import { UPDATE_TRUCK_REGISTRATION_DATA } from "../actions/actionTypes";
import { TruckRegistrationData } from "../types";

const initialState: TruckRegistrationData = {
  plateNumber: "",
  plateState: "",
  VIN: "",
  vehicleType: "",
  vehicleSubtype: "",
  VN: "",
  manufacturer: "",
  model: "",
  engine: "",
  year: 0,
  mileage: 0,
  doorType: "",
  liftGateType: "",
  length: "",
  cargoSecurementTrack: "",
  dockHeight: "",
  allowTravelOutOfState: "",
  gps: "",
  transmissionType: "",
  chassis: "",
  description: "",
  registrationStartDate: "",
  registrationEndDate: "",
  insuranceStartDate: "",
  insuranceEndDate: "",
  inspectionDate: "",
  enableForRent: false,
  rentPerDay: 0,
  rentPrepaidMileage: 0,
  enableForLease: false,
  leasePerDay: 0,
  leasePrepaidMileage: 0,
  deposit: 0,
  equipmentPrice: 0,
  insurancePrice: 0,
  taxes: 0,
  images: {
    threeQuarterView: "",
    front: "",
    passengerSide: "",
    back: "",
  },
  files: {
    vehicleRegistrationFile: "",
    vehicleInsuranceFile: "",
    technicalInspectionFile: "",
  },
  trailerType: "flatbed",
  crewCab: false,
  ramp: false,
  iftaComplaint: false,
  leaseForPurchase: false,
  organization: {
    dotNumber: "12345",
    name: "Another Test Company",
  },
};
export const updateTruckAddRegistrationData = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_TRUCK_REGISTRATION_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
export default updateTruckAddRegistrationData;
