import { FunctionComponent, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./css/UserAdministration.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setOrganization } from "../../store/actions/organizationAction";
import { REACT_APP_ORG_API_BASE_URL } from '../../utils/UrlConstants';

const AdminNavBarLeadingContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state);
  const userOrganization: string = reduxState.currentUser.organizations[0];

  useEffect(() => {
    const fetchOrganization = async (id: string) => {
      const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          dispatch(setOrganization(response.data.organization));
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
    };

    if (userOrganization) {
      fetchOrganization(userOrganization);
    }
  }, [userOrganization, dispatch]);

  return (
    <>
      <div>
        <div className={styles.leadingContent}>
          <div className={styles.header}>
            <Link to="/">
              <img
                className={styles.theLogoMakerBuiltFor1000}
                alt=""
                src="/logos/Truck4 logo-1.png"
                onClick={() => {
                  window.location.href = "/#/main";
                }}
              />
            </Link>
            <div className={styles.iconoutlinearrowHide}>
              <img className={styles.icon} alt="" src="/admin/icon.svg" />
              <div className={styles.iconoutlinearrowHideChild} />
            </div>
          </div>
          <div className={styles.navigation}>
            <Link
              to="/admin"
              style={{ width: "100%" }}
            >
              <div className={styles.verticalNavigationitem}>
                <div className={styles.content}>
                  <img
                    className={styles.homeIcon}
                    alt=""
                    src="/admin/home.svg"
                  />
                  <div className={styles.text}>Main Page</div>
                </div>
              </div>
            </Link>
            <div
              className={styles.verticalNavigationitem7}
              style={{ padding: "0px", marginLeft: "8px" }}
            >
              <Accordion style={{ boxShadow: "unset", width: "100%" }}>
                <AccordionSummary
                  style={{ padding: "unset" }}
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0 12px",
                    }}
                    className="disabledButton"
                  >
                    <div className={styles.content8}>
                      <img
                        className={styles.iconoutlinecog}
                        alt=""
                        src="/admin/iconoutlinebookopen.svg"
                      />
                    </div>
                    <div className={styles.text8}>Ads</div>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <Link to="/admin">
                    <div
                      className={styles.verticalNavigationitem9}
                      onClick={undefined}
                    >
                      <div className={`${styles.content10} disabledButton`}>
                        <div className={styles.home}>
                          <div className={styles.homeChild} />
                          <div className={styles.homeItem} />
                        </div>
                        <div className={styles.text9}>Reservations</div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/admin/user_administration">
                    <div
                      className={styles.verticalNavigationitem9}
                      onClick={undefined}
                    >
                      <div className={`${styles.content10} disabledButton`}>
                        <div className={styles.home2}>
                          <div className={styles.rectangleDiv} />
                          <div className={styles.homeChild1} />
                        </div>
                        <div className={styles.text11}>Dashboard</div>
                      </div>
                    </div>
                  </Link>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={styles.verticalNavigationitem2}>
              <div className={`${styles.content2} disabledButton`}>
                <img
                  className={styles.iconoutlinecurrencyDollar}
                  alt=""
                  src="/admin/iconoutlinecurrencydollar.svg"
                />
                <div className={styles.text2}>Finance</div>
              </div>
            </div>
            <div className={styles.verticalNavigationitem3}>
              <div className={`${styles.content3} disabledButton`}>
                <img
                  className={styles.iconoutlinechartSquareBar}
                  alt=""
                  src="/admin/iconoutlinechartsquarebar.svg"
                />
                <div className={styles.text3}>Marketing</div>
              </div>
            </div>
            <div className={styles.verticalNavigationitem4}>
              <div className={`${styles.content4} disabledButton`}>
                <img
                  className={styles.iconoutlinechartBar}
                  alt=""
                  src="/admin/iconoutlinechartbar.svg"
                />
                <div className={styles.text4}>Analytics</div>
              </div>
            </div>
            <div className={styles.verticalNavigationitem5}>
              <div className={`${styles.content5} disabledButton`}>
                <img
                  className={styles.iconoutlinechatAlt2}
                  alt=""
                  src="/admin/iconoutlinechatalt2.svg"
                />
                <div className={styles.text5}>Reviews and ratings</div>
              </div>
            </div>
            <div className={styles.navigationChild} />
            <div className={styles.verticalNavigationitem6}>
              <div className={`${styles.content6} disabledButton`}>
                <img
                  className={styles.iconoutlinechat}
                  alt=""
                  src="/admin/iconoutlinechat.svg"
                />
                <div className={styles.text6}>Support center</div>
              </div>
            </div>
            <div className={styles.verticalNavigationitem7}>
              <div className={`${styles.content7} disabledButton`}>
                <img
                  className={styles.iconoutlinequestionMarkCir}
                  alt=""
                  src="/admin/iconoutlinequestionmarkcircle.svg"
                />
                <div className={styles.text7}>FAQ</div>
              </div>
            </div>
            <Link to="/admin/truck_management">
              <div className={styles.verticalNavigationitem10}>
                <div className={styles.content10}>
                  <LocalShippingIcon />
                  <div className={styles.text11}>Truck Management</div>
                </div>
              </div>
            </Link>
            <Link to="/admin/user_administration">
              <div className={styles.verticalNavigationitem10}>
                <div className={styles.content10}>
                  <PersonIcon />
                  <div className={styles.text11}>User Administration</div>
                </div>
              </div>
            </Link>
            <div className={styles.verticalNavigationitem7}>
              <Accordion style={{ boxShadow: "unset", width: "100%" }}>
                <AccordionSummary
                  style={{ padding: "unset" }}
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14%",
                    }}
                  >
                    <div className={styles.content8}>
                      <img
                        className={styles.iconoutlinecog}
                        alt=""
                        src="/admin/iconoutlinecog.svg"
                      />
                    </div>
                    <div className={styles.text8}>Settings</div>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <Link to={`/admin/settings/profile`}>
                    <div className={`${styles.verticalNavigationitem9}`}>
                      <div className={`${styles.content10}`}>
                        <div className={styles.home}>
                          <div className={styles.homeChild} />
                          <div className={styles.homeItem} />
                        </div>
                        <div className={styles.text9}>Profile</div>
                      </div>
                    </div>
                  </Link>
                  <Link to={`/organization/${userOrganization}`}>
                    <div className={styles.verticalNavigationitem10}>
                      <div className={styles.content10}>
                        <div className={styles.home2}>
                          <div className={styles.rectangleDiv} />
                          <div className={styles.homeChild1} />
                        </div>
                        <div className={styles.text11}>Company</div>
                      </div>
                    </div>
                  </Link>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.verticalNavigation} onClick={undefined}>
        <div className={styles.content12}>
          <img
            className={styles.iconoutlinelightningBolt}
            alt=""
            src="/admin/iconoutlinelightningbolt.svg"
          />
          <div className={styles.text12}>Subscription</div>
        </div>
        <div className={`${styles.tag} hoverButtons`}>
          <div className={styles.text13}>Free plan</div>
        </div>
      </div>
      <div className={styles.footer} onClick={undefined}>
        <div className={styles.topBorder} />
        <div className={styles.content13}>
          <div className={styles.avatarWithText}>
            <img
              className={styles.avatarIcon}
              alt=""
              src="/admin/avatar@2x.png"
            />
            <div className={styles.text14} style={{ textAlign: "left" }}>
              <div className={styles.title}>{reduxState.organization.name}</div>
              <div className={styles.supportingText}>View profile</div>
            </div>
          </div>
          <img
            className={styles.iconoutlineselector}
            alt=""
            src="/admin/iconoutlineselector.svg"
          />
        </div>
      </div>
    </>
  );
};

export default AdminNavBarLeadingContent;
