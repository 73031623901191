import { FunctionComponent, useEffect } from "react";
import PricingPagesTier from "./PricingPagesTier";
import PricingPagesTier1 from "./PricingPagesTier1";
import Footer from "./Footer";
import styles from "./css/AddTruckInitial.module.css";
import styles2 from "./css/PricingPagesTier.module.css";
// import Navbar from "./Navbar";

const AddTruckNotOwner: FunctionComponent = ({}) => {
  const handleNextClick = () => {};

  return (
    <div className={styles.addTruck}>
      {/* <Navbar /> */}
      <main className={styles.rentFrame}>
        <div className={styles.pricingPagesText}>
          <h1 className={styles.publishTransport}>Publish transport</h1>
        </div>
        <section className={styles.shapesFrame}>
          <div className={styles2.pricingPagestier}>
            <div
              className={styles2.frameInstance}
              style={{ position: "relative" }}
            >
              <div className={styles2.tierFrameInstance}>
                <img
                  className={styles2.shapesIcon}
                  style={{ height: "unset !important" }}
                  loading="eager"
                  alt=""
                  src="/generics/shapes.png"
                />
              </div>
              <img
                className={""}
                style={{
                  height: "unset !important",
                  display: "block !important",
                  position: "absolute",
                  top: "-10vh",
                  left: "3vw",
                }}
                alt=""
                src="/generics/shapes.png"
              />
              <div className={styles2.rentButtonInstance}>
                <div
                  className={styles2.yourTruckYour}
                  style={{ textAlign: "center" }}
                >
                  In order to post a truck, <br />
                  you need to become a owner
                </div>
              </div>
            </div>
            <div className={styles2.supportingText}></div>
            <div className={styles2.pricingPagestierChild} />
            <div className={styles2.content2}>
              <div className={styles2.features}></div>
              <button
                className={styles2.button}
                style={{ marginBottom: "16px" }}
              >
                <div className={styles2.footerLinkContainer}>
                  Request role switch
                </div>
              </button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AddTruckNotOwner;
