import { useState, FunctionComponent } from "react";
import styles from "./Profile.module.css";
import axios from 'axios';
import {successToast, errorToast} from '../../../components/Toast/ToastMessage';
import Modal from 'react-bootstrap/Modal';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAuthHeaders } from "../../../utils/GetHeaders";
import { REACT_APP_AUTH_SVC_API_BASE_URL } from '../../../utils/UrlConstants';

interface PasswordModalProps {
  lgShow: boolean;
  setLgShow: (value: boolean) => void;
}

const PasswordModal: FunctionComponent<PasswordModalProps> = ({
  lgShow,
  setLgShow,
}) => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [formPasswordData, setFormPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const validateForm = () => {
    const { oldPassword, newPassword, confirmPassword } = formPasswordData;
    if (!oldPassword || !newPassword || !confirmPassword) {
      return false;
    }
    return true;
  };

  const handlePassswordChange = (e :any) => {
    const { id, value } = e.target;
    setFormPasswordData(prevState => ({
        ...prevState,
        [id]: value
    }));
  };

  const handleSubmit = async (e :any) => {
    e.preventDefault();

    if (validateForm()){
      try {
        const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/password/update`;
        const response :any = await axios.put(url, formPasswordData, {
          headers: getAuthHeaders(),
        });
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.headers.get('x-access-token'));
          localStorage.setItem("refreshToken", response.headers.get('x-refresh-token'));
          successToast("Password updated successfully!")
        } else {
          errorToast("Error while updating password!")
        }
      } catch (error :any) {
        errorToast(error.response.data.error)
      }
    } else{
      errorToast("Password fields should not be empty!");
    } 
  };

  return (
    <div>
      <Modal
        className={`${styles.updatePasswordModal} password-modal`}
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <b className={styles.modalTitle}>Password Update</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className={`${styles.profileForm} ${styles.passwordForm}`} onSubmit={handleSubmit}>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="oldPassword">Old password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                value={formPasswordData.oldPassword}
                onChange={handlePassswordChange}
              />
              <span
                onClick={() => setShowOldPassword(!showOldPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showOldPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
            </div>
          </div>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="newPassword">New password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                value={formPasswordData.newPassword}
                onChange={handlePassswordChange}
              />
              <span
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showNewPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
            </div>
          </div>
          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="confirmPassword">Confirm password</label>
            <div className="col-6" style={{ position: 'relative' }}>
              <input
                className={`${styles.passwordField} ${styles.inputField}`}
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                value={formPasswordData.confirmPassword}
                onChange={handlePassswordChange}
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '25%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}
              >
                {showConfirmPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
              </span>
                <p className={styles.minChars}>Minimum 6 characters</p>
            </div>
          </div>

          <div className={styles.horizontalLine}></div>

          <div className={`${styles.formGroup} row`}>
            <label className={`${styles.passwordLabel}`} htmlFor="confirmPassword">
              <button className={`${styles.btn} ${styles.btnRight} ${styles.btnLight}`}>
                Cancel
              </button>
            </label>
            <div className="col-6">
            <button type="submit" className={`${styles.btn} ${styles.btnRight}`}>
              Change
            </button>
            </div>
          </div>
        </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PasswordModal;
