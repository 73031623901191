import { FunctionComponent } from "react";
import styles from "./css/UserAdministration.module.css";
import AdminNavBarLeadingContent from './AdminNavBarLeadingContent'

const AdminSidebar: FunctionComponent = () => {
  return (
    <div className={styles.simpleWithIconsBadgesAnd}>
      <AdminNavBarLeadingContent />
    </div>
  );
};

export default AdminSidebar;
