// registrationReducer.ts
import { UPDATE_REGISTRATION_DATA } from "../actions/actionTypes";
import { RegistrationData } from "../types";

const initialState: RegistrationData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: "",
  role: "driver",
  organizations: [],
};

export const updateRegistrationData = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_REGISTRATION_DATA:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default updateRegistrationData;
