import { FunctionComponent, useEffect } from "react";
import PricingPagesTier from "./PricingPagesTier";
import PricingPagesTier1 from "./PricingPagesTier1";
import Footer from "./Footer";
import styles from "./css/AddTruckInitial.module.css";
// import Navbar from "./Navbar";

export interface AddTruckFirstPageProps {
  onNextButtonClick: () => void;
}

const AddTruckFirstPage: FunctionComponent<AddTruckFirstPageProps> = ({
  onNextButtonClick,
}) => {
  const handleNextClick = () => {
    onNextButtonClick();
  };

  return (
    <div className={styles.addTruck}>
      {/* <Navbar /> */}
      <main className={styles.rentFrame}>
        <div className={styles.pricingPagesText}>
          <h1 className={styles.publishTransport}>Publish transport</h1>
          {/* <div className={styles.chooseWhichWay}>
            Choose which way you will join the service
          </div> */}
        </div>
        <section className={styles.shapesFrame}>
          {/* <PricingPagesTier /> */}
          <PricingPagesTier1 onNextButtonClick={handleNextClick} />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AddTruckFirstPage;
