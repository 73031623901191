import React from 'react';

const Loader: React.FC = () => {
  return (
    <>
      <h4 style={{ width: '100%', textAlign: 'center', marginTop: '5px' }}>
        <div className="spinner-border" role="status" style={{ color: 'coral' }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </h4>
    </>
  );
};

export default Loader;
