import { FunctionComponent, useState, useCallback, useEffect } from "react";
import styles from "./css/AddTruckFinal.module.css";
import { useNavigate } from "react-router-dom";
import { REACT_APP_SHORT_COUNTDOWN } from '../../utils/UrlConstants';

const AddTruckFinal: FunctionComponent = () => {
  const [isDropdownMenuMultiSelectOpen, setDropdownMenuMultiSelectOpen] =
    useState<boolean>(false);

  const openDropdownMenuMultiSelect = useCallback(() => {
    setDropdownMenuMultiSelectOpen(true);
  }, []);

  const closeDropdownMenuMultiSelect = useCallback(() => {
    setDropdownMenuMultiSelectOpen(false);
  }, []);

  const [countdown, setCountdown] = useState<number>(parseInt(REACT_APP_SHORT_COUNTDOWN || '5'));
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      navigate("/main");
    }

    return () => {
      clearInterval(timer);
    };
  }, [countdown, navigate]);

  return (
    <>
      <div className={styles.registerATruckOrEdit7P}>
        <div className={styles.registerATruckOrEdit7PChild} />
        <div className={styles.modal}>
          <div className={styles.textButton}>
            <img className={styles.icon} alt="" src="/generics/icon.svg" />
            <div className={styles.text2}>Back&nbsp;</div>
          </div>
          <div className={styles.iconParent}>
            <img className={styles.icon1} alt="" src="/generics/icon.svg" />
            <div className={styles.userName}>
              Thank you for successfully registering truck on Truck4 website!
            </div>
          </div>
          <div className={styles.inputTextParent}>
            <div className={styles.inputText}>
              The track has been submitted for moderation, you will be notified
              when the registration process is completed
            </div>
            <div className={styles.inputText1}>
              Redirecting in {countdown} sec
            </div>
          </div>
          <div className={styles.button4}>
            <div className={styles.text3}>Go back to site</div>
          </div>
          <div className={styles.inputTextGroup}>
            <div className={styles.inputText2}>Length</div>
            <div className={styles.tagParent}>
              <div className={styles.tag}>
                <img
                  className={styles.earnRevenueFromYourIdleFl}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer}>Small</div>
              </div>
              <div className={styles.tag1}>
                <img
                  className={styles.earnRevenueFromYourIdleFl1}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer1}>Large</div>
              </div>
            </div>
          </div>
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText3}>Lift Gate</div>
            <div className={styles.tagGroup}>
              <div className={styles.tag2}>
                <img
                  className={styles.earnRevenueFromYourIdleFl2}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer2}>Rail Gate</div>
              </div>
              <div className={styles.tag3}>
                <img
                  className={styles.earnRevenueFromYourIdleFl3}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer3}>Tuck Under</div>
              </div>
              <div className={styles.tag4}>
                <img
                  className={styles.earnRevenueFromYourIdleFl4}
                  alt=""
                  src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                />
                <div className={styles.reefer4}>No Lift Gate</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTruckFinal;
