import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddOrganizationList from "../components/Registration/AddOrganizationList";
import AddOrganizationVerification2 from "../components/Registration/AddOrganizationVerification2";
import VechicleToRent from "../components/Registration/VechicleToRent";
import styles from "./RegistrationInitialOwner.module.css";
import RegistrationOwnerDetails from "./RegistrationOwnerDetails";
import RegistrationOwnerDetails2 from "./RegistrationOwnerDetails2";
import RegistrationOwnerFinal from "./RegistrationOwnerFinal";
import RegistrationOwnerFinalFiles from "./RegistrationOwnerFinalFiles";
import RegistrationOwnerFirst from "./RegistrationOwnerFirst";
import RegistrationOwnerPhoneVerification from "./RegistrationOwnerPhoneVerification";
import RegistrationOwnerShare from "./RegistrationOwnerShare";
import { RegistrationData } from "../store/types";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { RootState } from "../store/types";
import { updateRegistrationData as handleUpdateRegistrationData } from "../store/actions/registrationActions";
import SideAndNavbarOwner from "../components/Registration_Generics/SideAndNavbarOwner";
import Navbar2 from "../pages/Add_Truck/Navbar2";

type VechicleOwnerProps = {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  onBackButtonKeyDown: () => void;
  onClick: () => void;
  role?: string;
};

type OwnProps = {
  registrationData: RegistrationData;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
};

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToProps = {
  handleUpdateRegistrationData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ResitrationInitialOwner: FunctionComponent<OwnProps & PropsFromRedux> = ({
  registrationData,
  handleUpdateRegistrationData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onTheLogoMakerBuiltFor1000Click = useCallback(() => {}, []);

  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNextButtonClick = (step?: number) => {
    if (step !== undefined) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === 1) {
      navigate("/registration_driver");
    }
    setCurrentStep(currentStep - 1);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerFirst
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 2:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerShare
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 3:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerDetails
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 4:
        return (
          <div
            className="wrapper"
            style={{ backgroundColor: "#f8fafc", height: "100vh" }}
          >
            <SideAndNavbarOwner />
            <Navbar2 />
            <AddOrganizationList
              onSkipButtonClick={() => handleNextButtonClick(5)}
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </div>
        );
      case 5:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerDetails2
              onBackButtonClick={handleBackButtonClick}
              onSkipButtonClick={() => handleNextButtonClick(5)}
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              registerData={registrationData}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
            />
          </>
        );
      case 6:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerPhoneVerification
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => {}}
              role="button"
              registerData={registrationData}
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 7:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <AddOrganizationVerification2
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onSkipBackwards={() => handleNextButtonClick(4)}
            />
          </>
        );
      case 8:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerFinal
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 9:
        return (
          <>
            <SideAndNavbarOwner />
            <Navbar2 />
            <RegistrationOwnerFinalFiles
              onBackButtonClick={handleBackButtonClick}
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.ResitrationInitialOwner}>
      <div className={styles.unsplashx60ygfg5soyParent}>
        <img
          className={styles.unsplashx60ygfg5soyIcon}
          alt=""
          src="/generics/unsplashx60ygfg5soy@2x.png"
        />
        <div className={styles.inputTextParent}>
          <div className={styles.inputText}>One place to manage everything</div>
          <div className={styles.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
        <img
          className={styles.celesClubPVidiGruzovikhMaIcon}
          alt=""
          src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
        />
      </div>
      <div className={styles.alreadyHaveAccount}>
        <img
          className={styles.theLogoMakerBuiltFor1000}
          alt=""
          src="/logos/Truck4 logo-1.png"
          onKeyDown={() => {}}
          role="button"
          onClick={() => {
            window.location.href = "/#/main";
          }}
        />
        <div className={styles.inputTextGroup}>
          <div className={styles.inputText2}>Already have an account?</div>
          <div className={styles.button}>
            <div className={styles.text}>Log In</div>
          </div>
        </div>
      </div>
      <div className={styles.ResitrationInitialOwnerChild} />

      {renderCurrentStep()}
    </div>
  );
};

export default ResitrationInitialOwner;
