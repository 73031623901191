import React from "react";
import styles from "./MapPage.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.container1}>
          <div className={styles.links1}>
            <div className={styles.footerssimpleLink}>
              <Link to="#">
                <div className={styles.text7}>Terms & Conditions</div>
              </Link>
            </div>
            <div className={styles.footerssimpleLink}>
              <Link to="#">
                <div className={styles.text8}>Privacy Policy</div>
              </Link>
            </div>
            <div className={styles.footerssimpleLink}>
              <Link to="#">
                <div className={styles.text9}>Help Center</div>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.copyright}>
          © 2023 Roybam, Inc. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
