import React from "react";
import AdminSidebar from "../AdminSidebar";
import AdminNavBar from "../AdminNavBar";
import styles from "./Reservations.module.css";
import carouselStyles from "../../Map_Page/MapPage.module.css";
import Carousel from "react-bootstrap/Carousel";

const Reservations: React.FC = () => {
  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div style={{ display: "flex" }}>
        <AdminSidebar />
        <div className={styles.allContent}>
          <div className={styles.head}>
            <span className={styles.lightHeadText}>Ads/</span>{" "}
            <span className={styles.headText}>Reservation</span>
            <small className={styles.smallHeadText}>
              Hey there! This baby is a powerhouse, with a
            </small>
          </div>
          <div className={`${styles.imageContainer}`}>
            <div className={`${styles.verticleCard} col-3`}>
              <Carousel
                className={`${carouselStyles.vecicleCard2} ${styles.verticleCard}`}
                id="truck_container"
                interval={null}
              >
                {Object.entries({ a: "a", b: "b", c: "c" }).map(
                  ([key, value]) => (
                    <Carousel.Item key={key}>
                      <div
                        className="hoverButtons"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="frame-24031@3x.png"
                          className="hoverButtons"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </Carousel.Item>
                  ),
                )}
              </Carousel>
            </div>
            <div className={`${styles.imageContainerRight} col-lg-9 col-sm-6`}>
              <span className="d-block">
                <span className={styles.imageContainerRightText}>
                  2017 Peterbilt 579{" "}
                </span>
                <span className={styles.dot}></span>
                <span className={styles.imageContainerRightText}>
                  {" "}
                  Tandem Tractor{" "}
                </span>
                <span className={styles.dot}></span>
                <span className={styles.imageContainerRightText}>
                  {" "}
                  $122 / day{" "}
                </span>
                <span className={styles.dot}></span>
                <span className={styles.imageContainerRightText}> #232 </span>
                <span className={styles.activeButton}>Active</span>
              </span>
              <span className="d-block">
                <span className={styles.imageContainerLightText}>
                  Toledo, Ohio 43612 &nbsp;
                </span>
                <span className={styles.dot}></span>&nbsp;
                <span className={styles.ratingStar}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.48374 0.690983C6.78309 -0.230328 8.0865 -0.230327 8.38586 0.690983L9.45543 3.98278C9.5893 4.3948 9.97326 4.67376 10.4065 4.67376H13.8677C14.8364 4.67376 15.2392 5.91338 14.4555 6.48278L11.6553 8.51722C11.3048 8.77187 11.1582 9.22323 11.292 9.63526L12.3616 12.9271C12.6609 13.8484 11.6065 14.6145 10.8228 14.0451L8.02258 12.0106C7.6721 11.756 7.1975 11.756 6.84701 12.0106L4.04684 14.0451C3.26313 14.6145 2.20865 13.8484 2.508 12.9271L3.57757 9.63526C3.71145 9.22323 3.56479 8.77187 3.2143 8.51722L0.414131 6.48278C-0.369582 5.91338 0.0331936 4.67376 1.00192 4.67376H4.46312C4.89634 4.67376 5.2803 4.3948 5.41417 3.98278L6.48374 0.690983Z"
                      fill="#F59E0B"
                    />
                  </svg>
                </span>
                <span className={styles.imageContainerLightText}>
                  {" "}
                  5.0 (12) &nbsp;
                </span>
                <span className={styles.dot}></span>
                <span className={styles.imageContainerLightText}>
                  {" "}
                  512k Mileage &nbsp;
                </span>
              </span>
              <div className={styles.border}></div>
              <span className={styles.imageContainerLightColorText}>
                Post by:{" "}
              </span>{" "}
              <span className={styles.imageContainerLightText}>K1 company</span>
              <span className="d-block">
                <span className={`${styles.imageContainerAccept} ${styles.declineTypography}`}>
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-1"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
                      fill="white"
                    />
                  </svg>
                  Accept
                </span>
                <span className={`${styles.imageContainerDecline} ${styles.declineTypography}`}>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-1"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                      fill="#0F172A"
                    />
                  </svg>
                  Decline
                </span>
              </span>
            </div>
          </div>
          <div className={`${styles.imageContainer}`} style={{display: 'flex'}}>
            <div className={styles.subDiv}>
              <span>Price Per Day</span>
              <h4>$122</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Earned</span>
              <h4>$0</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Ad views per month</span>
              <h4>100</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Current invoices</span>
              <h4>0</h4>
            </div>
            <div className={styles.subDiv}>
              <span>Current invoices</span>
              <h4>0</h4>
            </div>
          </div>

          <div className={styles.frameDiv}>
            {/* <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: 450 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map(
                        (column) =>
                          column.name !== "_id" && (
                            <TableCell
                              style={{
                                backgroundColor: "#F8FAFC",
                                fontWeight: "600",
                                color: "gray",
                                textAlign:
                                  column.id != "name"
                                    ? "center"
                                    : "inherit",
                              }}
                              key={column.id}
                            >
                              {column.name}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows
                        .slice(
                          page * rowPerPage,
                          page * rowPerPage + rowPerPage
                        )

                        .map((row, i) => {
                          return (
                            <TableRow key={i} className={styles.tableRow}>
                              {columns &&
                                columns
                                  .filter((column) => column.id !== "_id") // Exclude "_id" column
                                  .map((column, i) => {
                                    let value = row[column.id];
                                    return (
                                      <TableCell
                                        key={value}
                                        style={{
                                          textAlign:
                                            column.id != "name"
                                              ? "center"
                                              : "inherit",
                                          padding:
                                            column.id === "edit" ||
                                            column.id === "delete"
                                              ? "16px 0"
                                              : "16px",
                                        }}
                                      >
                                        {column.id === "name" && (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <img
                                              src="/admin/avatar@2x.png"
                                              alt="Avatar"
                                              style={{
                                                marginRight: "5px",
                                                width: "4vh",
                                                height: "4vh",
                                              }}
                                            />
                                            <div>
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {row.name}
                                              </span>
                                              <br />
                                              <span>{row.status}</span>
                                            </div>
                                          </div>
                                        )}
                                        {column.id !== "name" && (
                                          <span
                                            onClick={
                                              column.id === "edit"
                                                ? () => handleOpen(row._id)
                                                : column.id === "delete"
                                                ? () =>
                                                    handleDeleteTruck(
                                                      row._id
                                                    )
                                                : undefined
                                            }
                                            style={{
                                              borderRadius: "16px",
                                              padding: "4%",
                                              backgroundColor:
                                                value === "Active"
                                                  ? "orange"
                                                  : value === "Incomplete"
                                                  ? "#2bbdbd"
                                                  : value === "Frozen"
                                                  ? "gray"
                                                  : value === "On hold"
                                                  ? "#8282e9"
                                                  : "none",
                                              color:
                                                column.id === "edit"
                                                  ? "#fa6f32"
                                                  : column.id === "delete"
                                                  ? "red"
                                                  : value === "Active"
                                                  ? "white"
                                                  : value ===
                                                      "Incomplete" ||
                                                    value === "Driver" ||
                                                    value === "On hold" ||
                                                    value === "Frozen"
                                                  ? "white"
                                                  : "inherit",

                                              fontWeight:
                                                column.id === "edit"
                                                  ? "bold"
                                                  : column.id === "delete"
                                                  ? "bold"
                                                  : "normal",
                                            }}
                                          >
                                            {value}
                                            {column.id === "edit"
                                              ? "Edit"
                                              : ""}
                                            {column.id === "delete" ? (
                                              <DeleteForever />
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        )}
                                      </TableCell>
                                    );
                                  })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className={styles.tablePagination}
                sx={{ display: "flex", alignItems: "baseline" }}
                rowsPerPageOptions={[5, 10, 25]}
                page={page}
                rowsPerPage={rowPerPage}
                component="div"
                count={rows.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPage}
              ></TablePagination>
            </Paper> */}
          </div>

        </div>
      </div>
    </>
  );
};

export default Reservations;
