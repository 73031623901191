import { FunctionComponent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import Modal from "@mui/material/Modal";
import styles from "./css/UserAdministration.module.css";
import AdminSidebar from "../../pages/Admin/AdminSidebar";
import AddTruckInitial from "../Add_Truck/AddTruckInitial";
import { TruckRegistrationData } from "../../store/types";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForever from "@mui/icons-material/DeleteForever";
import axios from "axios";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import Loader from "../../components/Loader/Loader";
import AdminNavBar from "./AdminNavBar";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_ORG_API_BASE_URL, REACT_APP_PAGINATION_OFFSET, REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_TRUCK_API_BASE_URL } from '../../utils/UrlConstants';

export interface Column {
  id: string;
  name: string;
}

export interface TableTypes {
  columns: Column[];
  rows: { [key: string]: string | void }[];
}

const TruckManagement: FunctionComponent<TruckRegistrationData> = (
  TruckRegistrationData,
  handleUpdateTruckRegistrationData
) => {

  const [idToUpdate, setIdToUpdate] = useState<TruckRegistrationData>();
  const [truckRegistrationDataToUpdate, setTruckRegistrationDataToUpdate] = useState<TruckRegistrationData>({} as TruckRegistrationData);
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const [rows, setRows] = useState<{ [key: string]: string }[]>([]);
  const [filteredRows, setFilteredRows] = useState<{ [key: string]: string }[]>([{}]);
  const [filter, setFilter] = useState<string>("all");
  const [page, pageChange] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(5);
  const [loading, setloading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [trucks, setTrucks] = useState<TruckRegistrationData[]>([]);
  const [fullDataCount, setfullDataCount] = useState<number>(0);
  const [userRole, setUserRole] = useState<string>("");
  const [userTrucks, setUserTrucks] = useState<any>([]);
  const [userOrganizations, setUserOrganizations] = useState<string>("");
  const [isFetched, setIsFetched] = useState<boolean>(false);

  //PAGINATION
  const offset: number = parseInt(REACT_APP_PAGINATION_OFFSET || '8');

  const fetchUserTrucks = async (id: string) => {
    setloading(true);
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}/trucks?page=1?pageSize=10`;
    try {
      const response = await axios.get(url);

      console.log("fetch user trucks");
      console.log(response.data);
      let trucks = response.data.trucks;

      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: truck.manufacturer,
        status: truck.status,
        year: truck.year.toString(),
        earnings: truck.rentPerDay.toString(),
        location: truck.plateState.toUpperCase(),
      }));

      setRows(formattedTrucks);
      setFilteredRows(formattedTrucks);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      console.error(error, error);
    }
  };

  const handleDeleteTruck = async (id: string) => {
    const isConfirmed = confirm("Are you sure you want to delete this truck?");
    if (isConfirmed) {
      await deleteTruck(id);
      fetchTrucks();
    }
  };

  const columns: Column[] = [
    { id: "_id", name: "_id" },
    { id: "name", name: "NAME" },
    { id: "status", name: "STATUS" },
    { id: "year", name: "YEAR" },
    { id: "lastInvoice", name: "LAST INVOICE" },
    { id: "earnings", name: "EARN/DAY" },
    { id: "location", name: "LOCATION" },
    { id: "adStatus", name: "adStatus" },
    { id: "edit", name: "" },
    { id: "delete", name: "" },
  ];

  const handleChangePage = (event: any, newPage: any) => {
    pageChange(newPage);
  };

  const handleRowsPerPage = (event: any) => {
    setRowPerPage(event.target.value);
    pageChange(0);
  };

  const handleOpen = async (id: string) => {
    await fetchEditingTruck(id);
  };

  const fetchUser = async () => {
    var url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me`;
    try {
      console.log(url);
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      console.log(response.data.user);
      setUserRole(response.data.user.role);
      setUserOrganizations(response.data.user.organizations[0]);
      setIsFetched(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  useEffect(() => {
    if (userOrganizations) fetchUserTrucks(userOrganizations);
  }, [userOrganizations]);

  const handleRowClick = (id: string) => (e: React.MouseEvent) => {
    if (e.target instanceof HTMLElement) {
      const clickedId = e.target.id;
      if (clickedId !== "edit" && clickedId !== "delete") {
        window.open(`#/truck/${id}`, "_blank");
      }
    }
  };
  const reduxState = useSelector((state: any) => state);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
      if (userRole === "admin") {
        fetchTrucks();
      } else {
        if (isFetched) {
          fetchOrganizationTrucks(reduxState.organization._id);
        }
      }
    };

    fetchData();
  }, [currentPage, userOrganizations]);

  const deleteTruck = async (id: string) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/truck/${id}`;
    try {
      console.log(url);
      const response = await axios.delete(url, {
        headers: getAuthHeaders(),
      });
      successToast("Truck removed successfully");
    } catch (error) {
      errorToast("Error while removing truck");
      console.error(error, error);
    }
  };
  const fetchEditingTruck = async (id: string) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/truck/${id}`;
    try {
      const response = await axios.get(url);
      setIdToUpdate(response.data.truck);
      setTruckRegistrationDataToUpdate(response.data.truck);
      setOpen(true);
    } catch (error) {
      console.error(error, error);
    }
  };

  const fetchTrucks = async (query: any = null) => {
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?offset=${
      offset * page
    }&page=${page}`;
    if (query != null) {
      url = `${
        REACT_APP_TRUCK_API_BASE_URL
      }/trucks?offset=${offset}&page=${query.page || 1}`;
    }
    try {
      const response = await axios.get(
        url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&")
      );

      let trucks = response.data.trucks;
      trucks = trucks.reverse();

      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: truck.manufacturer,
        status: truck.status,
        year: truck.year.toString(),
        lastInvoice: "No Invoice",
        earnings: truck.rentPerDay.toString(),
        location: truck.plateState.toUpperCase(),
        adStatus: truck.enableForLease.toString(),
      }));
      setRows(formattedTrucks);
      setFilteredRows(formattedTrucks);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      setTrucks([]);
      setfullDataCount(0);
      setloading(false);
      errorToast("Error fetching trucks");
      console.error("Error fetching trucks:", error);
    }
  };

  const filterUsers = (filter: string) => {
    pageChange(0);
    let filteredData: any;
    switch (filter) {
      case "all":
        setFilteredRows(rows);
        setFilter("all");
        break;
      case "active":
        filteredData = rows.filter((row) => row.status === "active");
        setFilter("active");
        setFilteredRows(filteredData);
        break;
      case "pending":
        filteredData = rows.filter((row) => row.status === "pending");
        setFilter("pending");
        setFilteredRows(filteredData);
        break;
      case "on hold":
        filteredData = rows.filter((row) => row.status === "on hold");
        setFilter("on hold");
        setFilteredRows(filteredData);
        break;
      case "hidden":
        filteredData = rows.filter((row) => row.status === "hidden");
        setFilter("hidden");
        setFilteredRows(filteredData);
        break;
      case "in rent":
        filteredData = rows.filter((row) => row.status === "in rent");
        setFilter("in rent");
        setFilteredRows(filteredData);
        break;
      default:
        setFilteredRows(rows);
        setFilter("all");
        break;
    }
  };

  const fetchOrganizationTrucks = async (id: string | null = null) => {
    var url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}/trucks?page=1?pageSize=10`;
    try {
      const response = await axios.get(url);
      console.log("org stuff vvv");
      console.log(response.data.trucks);
      setUserTrucks(response.data.trucks);

      let trucks = response.data.trucks;
      const formattedTrucks = trucks.map((truck: TruckRegistrationData) => ({
        _id: truck._id,
        name: truck.manufacturer,
        status: truck.status,
        year: truck.year.toString(),
        lastInvoice: "not found",
        earnings: truck.rentPerDay.toString(),
        location: truck.plateState.toUpperCase(),
        adStatus: "Enable for Lease not found",
      }));

      setRows(formattedTrucks);

      console.log("User trucks (rows) vv");
      console.log(rows);

      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);
      setloading(false);
    } catch (error) {
      setfullDataCount(0);
      setloading(false);
      errorToast("Error fetching organization");
      errorToast("No trucks");
      console.error("Error fetching organization:", error);
    }
  };

  const filteredColumns = columns.filter((column) => {
    if (
      userRole !== "admin" &&
      (column.id === "edit" || column.id === "delete")
    ) {
      return false;
    }
    return true;
  });

  return (
    <>
      <div className={styles.adminNavBar}>
        <AdminNavBar />
      </div>
      <div style={{ display: "flex" }}>
        <AdminSidebar />
        <div className={styles.userAdministration}>
          <div className={styles.textParent}>
            <div className={styles.text15}>
              <span>Settings /</span>
              <span className={styles.userAdministration1}>
                {" "}
                Truck administration
              </span>
            </div>
            <div className={styles.heyThereThisBabyIsAPoweParent}>
              <div className={styles.heyThereThis}>
                Hey there! This baby is a powerhouse, with lots of
                possibilities!
              </div>
              <div className={styles.buttonParent}>
                <div className={styles.button}>
                  <img
                    className={styles.iconoutlinecog1}
                    alt=""
                    src="/admin/iconoutlinecog1.svg"
                  />
                  <div className={styles.text16}>Fleet settings</div>
                </div>
                <div className={styles.button1}>
                  <div className={styles.text17}>Connect your Database</div>
                </div>
                <Link to="/add_truck">
                  <div className={`${styles.button2} hoverButtons`}>
                    <img
                      className={styles.iconoutlineuserAdd}
                      alt=""
                      style={{ filter: "contrast(0%) brightness(2)" }}
                      src="/truck/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1-4@2x.png"
                    />
                    <div className={styles.text17}>Add truck</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className={styles.numberCardParent}>
              <div className={styles.numberCard}>
                <div className={styles.creditsUsedParent}>
                  <div className={styles.creditsUsed}>Published Trucks</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div}>{trucks.length} trucks</div>
                <div className={styles.numberCardInner}>
                  <div className={styles.frameChild} />
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>
                    Vechicles Incomplete
                  </div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {
                    trucks.filter((truck) => truck.status === "incomplete")
                      .length
                  }
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles Frozen</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "frozen").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles on Hold</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {trucks.filter((truck) => truck.status === "on hold").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles Hidden</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "hidden").length}
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.creditsUsedGroup}>
                  <div className={styles.creditsUsed1}>Vechicles in Rent</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir1}
                    alt=""
                    src="/admin/main/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.div1}>
                  {" "}
                  {trucks.filter((truck) => truck.status === "in rent").length}
                </div>
              </div>
            </div>
            <div className={styles.tabExampleLightDesktopParent}>
              <div className={styles.tabExampleLightDesktop}>
                <div className={styles.tabExampleLightDesktop1}>
                  <div className={styles.tabs}>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "all"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab1Wrapper}
                        onClick={() => {
                          filterUsers("all");
                        }}
                      >
                        <div className={styles.tab3}>All</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "active"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("active");
                        }}
                      >
                        <div className={styles.tab3}>Active</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "pending"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab3Wrapper}
                        onClick={() => {
                          filterUsers("pending");
                        }}
                      >
                        <div className={styles.tab3}>Pending</div>
                      </div>
                      <div className={styles.desktopTabInner} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "on hold"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab4Wrapper}
                        onClick={() => {
                          filterUsers("on hold");
                        }}
                      >
                        <div className={styles.tab4}>On hold</div>
                      </div>
                      <div className={styles.desktopTabChild1} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "hidden"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab5Wrapper}
                        onClick={() => {
                          filterUsers("hidden");
                        }}
                      >
                        <div className={styles.tab5}>Hidden</div>
                      </div>
                      <div className={styles.desktopTabChild2} />
                    </div>
                    <div
                      className={styles.desktopTab2}
                      style={
                        filter === "in rent"
                          ? {
                              borderBottom: "2px solid #fa6f32",
                              color: "#fa6f32",
                            }
                          : {}
                      }
                    >
                      <div
                        className={styles.tab6Wrapper}
                        onClick={() => {
                          filterUsers("in rent");
                        }}
                      >
                        <div className={styles.tab6}>In rent</div>
                      </div>
                      <div className={styles.desktopTabChild3} />
                    </div>
                  </div>
                  <div className={styles.underLine} />
                </div>
              </div>
              <div className={styles.frameDiv}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Paper sx={{ width: "100%" }}>
                      <TableContainer sx={{ minHeight: 450, maxHeight: 1000 }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {filteredColumns.map(
                                (column, key) =>
                                  column.name !== "_id" && (
                                    <TableCell
                                      style={{
                                        backgroundColor: "#F8FAFC",
                                        fontWeight: "600",
                                        color: "gray",
                                        textAlign:
                                          column.id !== "name"
                                            ? "center"
                                            : "inherit",
                                      }}
                                      key={key}
                                    >
                                      {column.name}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredRows &&
                              filteredRows
                                .slice(
                                  page * rowPerPage,
                                  page * rowPerPage + rowPerPage
                                )
                                .map((row, rowIndex) => (
                                  <TableRow
                                    key={rowIndex}
                                    className={styles.tableRow}
                                    onClick={handleRowClick(row._id)}
                                  >
                                    {columns &&
                                      columns
                                        .filter((column) => column.id !== "_id")
                                        .map((column) => {
                                          const value = row[column.id];
                                          return (
                                            <TableCell
                                              key={`${row._id}-${column.id}`}
                                              style={{
                                                textAlign:
                                                  column.id !== "name"
                                                    ? "center"
                                                    : "inherit",
                                                padding:
                                                  column.id === "edit" ||
                                                  column.id === "delete"
                                                    ? "16px 0"
                                                    : "16px",
                                              }}
                                            >
                                              {column.id === "name" && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src="/admin/truck_image.png"
                                                    alt="Avatar"
                                                    style={{
                                                      marginRight: "5px",
                                                      width: "4vh",
                                                      height: "4vh",
                                                    }}
                                                  />
                                                  <div>
                                                    <span
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {row.name}
                                                    </span>
                                                    <br />
                                                    <span>{row.status}</span>
                                                  </div>
                                                </div>
                                              )}
                                              {column.id !== "name" && (
                                                <span
                                                  onClick={
                                                    column.id === "edit"
                                                      ? (e) => {
                                                          e.stopPropagation(); // Stop propagation to prevent the row click event
                                                          handleOpen(row._id);
                                                        }
                                                      : column.id === "delete"
                                                      ? () =>
                                                          handleDeleteTruck(
                                                            row._id
                                                          )
                                                      : undefined
                                                  }
                                                  style={{
                                                    borderRadius: "16px",
                                                    padding: "4%",
                                                    backgroundColor:
                                                      value === "active"
                                                        ? "orange"
                                                        : value === "incomplete"
                                                        ? "#2bbdbd"
                                                        : value === "frozen"
                                                        ? "gray"
                                                        : value === "on hold"
                                                        ? "#8282e9"
                                                        : value === "in rent"
                                                        ? "#3fab4e"
                                                        : "none",
                                                    color:
                                                      column.id === "edit"
                                                        ? "#fa6f32"
                                                        : column.id === "delete"
                                                        ? "red"
                                                        : value === "active"
                                                        ? "white"
                                                        : value === "in rent"
                                                        ? "white"
                                                        : value ===
                                                            "incomplete" ||
                                                          value === "driver" ||
                                                          value === "on hold" ||
                                                          value === "frozen"
                                                        ? "white"
                                                        : "inherit",
                                                    zIndex:
                                                      column.id === "edit"
                                                        ? "999"
                                                        : "1",
                                                    fontWeight:
                                                      column.id === "edit"
                                                        ? "bold"
                                                        : column.id === "delete"
                                                        ? "bold"
                                                        : "normal",
                                                  }}
                                                >
                                                  {value}
                                                  {column.id === "edit" &&
                                                  userRole === "admin"
                                                    ? "Edit"
                                                    : ""}
                                                  {column.id === "delete" &&
                                                  userRole === "admin" ? (
                                                    <DeleteForever />
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              )}
                                            </TableCell>
                                          );
                                        })}
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        className={styles.tablePagination}
                        sx={{ display: "flex", alignItems: "baseline" }}
                        rowsPerPageOptions={[5, 10, 25]}
                        page={page}
                        rowsPerPage={rowPerPage}
                        component="div"
                        count={filteredRows.length}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPage}
                      ></TablePagination>
                    </Paper>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box
            sx={{
              ...style, 
              width: "50%", 
              "@media (max-width: 1100px)": {
                width: "96%", 
              },
            }}
          >
            <CancelIcon
              sx={{
                position: "fixed",
                zIndex: "2",
                width: "30px",
                height: "30px",
                right: "3%",
                "&:hover": {
                  cursor: "pointer",
                  fill: "gray",
                },
              }}
              onClick={handleClose}
            />
            <AddTruckInitial
              TruckRegistrationData={truckRegistrationDataToUpdate}
              handleUpdateTruckRegistrationData={handleUpdateTruckRegistrationData}
              showSidebar={false}
              autoClick={true}
              idToUpdate={idToUpdate}
            />
          </Box>
        </>
      </Modal>

    </>
  );
};

export default TruckManagement;

const style = {
  position: "relative",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "95vh",

  // height: 500,
  bgcolor: "#f8fafc",
  border: "1px solid #fff",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
  "& > form > div": {
    width: "unset",
  },
  "& > form": {
    backgroundColor: "unset",
  },
  "& > div > div": {
    left: "0",
    width: "unset",
    margin: "auto",
  },
  "@media (max-width: 750px)": {
    width: "90%",
    maxHeight: "90vh",
    padding: "2rem",
  },
  "@media (max-width: 500px)": {
    width: "100%",
    maxHeight: "100vh",
    padding: "1rem",
  },
};
