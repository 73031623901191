import React, { useEffect, useState } from "react";
import styles from "./ProductPage.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, InputAdornment, ThemeProvider } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { Tab, Tabs, Typography, Box } from "@mui/material";
import { theme } from "../Map_Page/MapPageSelector";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_TRUCK_API_BASE_URL } from '../../utils/UrlConstants';

interface InfoContainerProps {
  truck: any;
  mail: string;
  ownerNumber: string;
}

const InfoContainer: React.FC<InfoContainerProps> = ({
  truck,
  mail,
  ownerNumber,
}) => {
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [showPhoneNumber2, setShowPhoneNumber2] = useState<boolean>(false);

  const [openFrom, setOpenFrom] = useState<boolean>(false);
  const [openTo, setOpenTo] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [truckId, setTruckId] = useState<string>("");
  const [bookingFlag, setBookingFlag] = useState<boolean>(false);

  const [value, setValue] = useState<Dayjs | null>(dayjs("2022-04-17T15:30"));

  const [agreementType, setAgreementType] = useState<number>(0);

  const handleAgreementType = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAgreementType(newValue);
  };

  const handleClickFrom = () => {
    setOpenFrom(true);
  };

  useEffect(() => {
    setTruckId(truck._id);

    console.log(fromDate);
  }, [truck]);

  useEffect(() => {
    if (fromDate != null && toDate != null) setBookingFlag(true);
    else setBookingFlag(false);
  }, [fromDate, toDate]);

  const handleCloseFrom = () => {
    setOpenFrom(false);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
    handleCloseFrom();
  };

  const handleBooking = async () => {
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        errorToast("From date cannot be greater than To date");
        return;
      }

      try {
        const response = await fetch(
          `${REACT_APP_TRUCK_API_BASE_URL}/truck/${truckId}/booking`,
          {
            method: "POST",
            headers: getAuthHeaders(),
            body: JSON.stringify({
              agreementType:
                agreementType === 0
                  ? "Rent"
                  : agreementType === 1
                  ? "Lease"
                  : agreementType === 2
                  ? "Lease for Purchase"
                  : "Unknown",
              startDate: fromDate,
              endDate: toDate,
              terms: "Terms?",
            }),
          }
        );

        const data = await response.json();
        if (!data.success) {
          console.log(data);
          errorToast(`Failed to book: ${data.message}`);
        } else {
          console.log(data);
          successToast(`Booked successfully!`);
        }
      } catch (error: any) {
        errorToast(`Error: ${error.message}`);
      }
    } else {
      errorToast("Please select 'From' and 'To' dates");
    }
  };

  const handleClickTo = () => {
    setOpenTo(true);
  };

  const handleCloseTo = () => {
    setOpenTo(false);
  };

  const handleToDateChange = (date: any) => {
    setToDate(date);
    handleCloseTo();
  };
  return (
    <div className={styles.frameParent5}>
      {/* <div
        className={styles.button3}
        onClick={() => {
          setShowPhoneNumber(!showPhoneNumber);
        }}
      >
        {showPhoneNumber ? (
          <div className={styles.phoneNumberDiv}>
            <b>{ownerNumber}</b>
          </div>
        ) : (
          <>
            <img
              className={styles.iconsolidphone}
              alt=""
              src="/truck/iconsolidphone.svg"
            />
            <div className={styles.text17}>Show phone number</div>
          </>
        )}
      </div> */}
      <div className={styles.tabExampleLightDesktopParent}>
        <div className={styles.tabExampleLightDesktop}>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                flexGrow: 1,

                bgcolor: "background.paper",
                width: "inherit",
              }}
            >
              <Tabs
                style={{ minHeight: "unset" }}
                value={agreementType}
                onChange={handleAgreementType}
                aria-label="scrollable auto tabs example"
                indicatorColor="secondary"
                textColor="primary"
              >
                <Tab className={styles.infoContainerTabs} label="Rent" />
                <Tab className={styles.infoContainerTabs} label="Lease" />
                <Tab
                  className={styles.infoContainerTabs}
                  label="Lease to purchase"
                />
              </Tabs>
            </Box>
          </ThemeProvider>
          <div className={styles.underLine} />
        </div>
        <div className={styles.usd81dailyParent}>
          <div className={styles.usd81daily}>USD ${truck.rentPerDay}/daily</div>
          <div className={styles.usd025ratePer}>
            USD ${truck.rentPrepaidMileage}/rate per mile
          </div>
        </div>
        <div className={styles.frameChild2} />
        <div className={styles.frameParent6}>
          <div
            className={styles.inputWithLabelContainer}
            style={{ width: "100%" }}
          >
            <div className={styles.inputWithLabel4}>
              <TextField
                id="input-with-icon-textfield"
                label="From"
                value={fromDate ? format(fromDate, "yyyy/MM/dd") : ""}
                onClick={handleClickFrom}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <DatePicker
                popperClassName={styles.datePickerCalendar}
                open={openFrom}
                selected={fromDate}
                onChange={handleFromDateChange}
                onClickOutside={handleCloseFrom}
                className={styles.datePicker}
                dateFormat="yyyy/MM/dd"
              />
            </div>
            <div className={styles.inputWithLabel5}>
              <TextField
                id="input-with-icon-textfield"
                label="To"
                value={toDate ? format(toDate, "yyyy/MM/dd") : ""}
                onClick={handleClickTo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <DatePicker
                popperClassName={styles.datePickerCalendar}
                open={openTo}
                selected={toDate}
                onChange={handleToDateChange}
                onClickOutside={handleCloseTo}
                className={styles.datePicker}
                dateFormat="yyyy/MM/dd"
              />
            </div>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["TimePicker", "TimePicker"]}
              sx={{ width: "100%" }}
            >
              <TimePicker
                sx={{ width: "100%" }}
                label="Pick up time"
                value={value}
                onChange={(newValue) => setValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <div className={styles.checkbox23}>
            <Form.Check aria-label="option 1" />
            <div
              className={styles.textDescription12}
            >I accept the Terms & Conditions</div>
          </div>
        </div>
        <div className={styles.frameChild3} />
        <div className={styles.buttonParent}>
          <div
            className={bookingFlag ? styles.button4 : styles.button2}
            onClick={handleBooking}
          >
            <div className={styles.text16}>Book</div>
          </div>
          <div
            className={styles.button3}
            onClick={() => {
              setShowPhoneNumber(!showPhoneNumber);
            }}
          >
            {showPhoneNumber ? (
              <div className={styles.phoneNumberDiv}>
                <b>{ownerNumber}</b>
              </div>
            ) : (
              <>
                <img
                  className={styles.iconsolidphone}
                  alt=""
                  src="/truck/iconsolidphone.svg"
                />
                <div className={styles.text17}>Show phone number</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.button4}>
        <a href={`mailto:${mail}}`}>
          <div className={styles.text18}>Send message to owner</div>
        </a>
      </div>
      {/* <Accordion
        sx={{
          width: "100%",
          boxShadow: "unset",
          border: "unset",
          position: "relative",
          "&::before": {
            backgroundColor: "unset",
          },
        }}
      >
        <AccordionSummary
          className={styles.frameWrapper1}
          style={{ flexDirection: "row", margin: "auto" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={styles.spanContainer}>
            <span className={styles.spanTitle}>
              <b>Daily Rate </b>
            </span>
            <HelpOutlineIcon style={{ fill: "#A8B4C6" }} />
          </div>

          <span>
            <b>${parseFloat(truck.rentPerDay).toFixed(2)}</b>
          </span>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "8px 0 0 0",
          }}
        >
          <div className={styles.frameParent8}>
            <div className={styles.frameChild7} />
            <div className={styles.frameParent10}>
              <div className={styles.frameParent11}>
                <div className={styles.headlineContainer}>
                  <div className={styles.headline4}>Discounts</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir3}
                    alt=""
                    src="/truck/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.headline5}>Total</div>
              </div>
              <div className={styles.subtextParent}>
                <div className={styles.subtext}>Discounts</div>
                <div className={styles.subtext1}>$0</div>
              </div>
            </div>
            <div className={styles.frameChild8} />
            <div className={styles.frameParent12}>
              <div className={styles.frameParent13}>
                <div className={styles.headlineParent1}>
                  <div
                    className={styles.headline6}
                  >{`Insurance & Roadside`}</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir4}
                    alt=""
                    src="/truck/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.headline7}>$0</div>
              </div>
              <div className={styles.subtextContainer}>
                <div className={styles.subtext4}>Physical Damage Waiver</div>
                <div className={styles.subtext5}>-$0</div>
              </div>
              <div className={styles.subtextParent1}>
                <div className={styles.subtext6}>Roadside</div>
                <div className={styles.subtext7}>-$0</div>
              </div>
            </div>
            <div className={styles.frameChild9} />
            <div className={styles.frameParent14}>
              <div className={styles.frameParent15}>
                <div className={styles.headlineParent2}>
                  <div className={styles.headline8}>Prepaid Mileage</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir5}
                    alt=""
                    src="/truck/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.headline9}>
                  ${truck.rentPrepaidMileage}
                </div>
              </div>
              <div className={styles.subtext8}>
                250 miles per day included ($0.22 per extra mile)
              </div>
            </div>
            <div className={styles.frameChild10} />
            <div className={styles.frameParent16}>
              <div className={styles.frameParent17}>
                <div className={styles.headlineParent3}>
                  <div className={styles.headline10}>{`Taxes & Fees`}</div>
                  <img
                    className={styles.iconoutlinequestionMarkCir6}
                    alt=""
                    src="/truck/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.headline11}>${truck.taxes}</div>
              </div>
              <div className={styles.subtextParent2}>
                <div className={styles.subtext9}>Tax</div>
                <div className={styles.subtext10}>${truck.taxes}</div>
              </div>
              <div className={styles.subtextParent3}>
                <div className={styles.subtext11}>Service Fees</div>
                <div className={styles.subtext12}>$ ??</div>
              </div>
              <div className={styles.subtextParent4}>
                <div className={styles.subtext13}>Processing Fees</div>
                <div className={styles.subtext14}>$ ??</div>
              </div>
            </div>
            <div className={styles.frameChild11} />
            <div className={styles.frameParent18}>
              <div className={styles.headlineParent4}>
                <div className={styles.headline12}>Total</div>
                <div className={styles.headline13}>${truck.taxes}</div>
              </div>
              <div className={styles.accordion}>
                <img
                  className={styles.iconsolidcheveronUp1}
                  alt=""
                  src="/truck/iconsolidcheveronup1.svg"
                />
                <div className={styles.subtextParent5}>
                  <div className={styles.subtext15}>
                    + Refundable Security Deposit
                  </div>
                  <img
                    className={styles.iconoutlinequestionMarkCir7}
                    alt=""
                    src="/truck/iconoutlinequestionmarkcircle1.svg"
                  />
                </div>
                <div className={styles.subtext16}>TBD</div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion> */}
      {/* <div className={styles.component2}>
        <img
          className={styles.informationCircleIcon}
          alt=""
          src="/truck/information-circle.svg"
        />
        <div className={styles.content}>
          <div className={styles.title}>
            Anything else you’d like to know? Message us
          </div>
          <div className={styles.alertstextLink}>
            <div className={styles.text21}>Details →</div>
          </div>
        </div>
      </div>
      <div className={styles.iconoutlineflagParent}>
        <img
          className={styles.iconoutlineflag}
          alt=""
          src="/truck/iconoutlineflag1.svg"
        />
        <div className={styles.reportAnAd}>Report an ad</div>
      </div> */}
    </div>
  );
};

export default InfoContainer;
