import {
    SET_USER,
  } from "../actions/actionTypes";
  import { CurrentUserData } from "../types";

const initialState: CurrentUserData = {
    emailVerification: {
        active: false,
      },
      phoneVerification: {
        active: false,
      },
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",
      active: false,
      organizations: [],
      trucks: [],
      additionalDocs: null,
      isBlocked: false,
      refreshTokens: [],
      createdAt: "",
      lastActivity: "",
      __v: 0,
};

export const currentUserData = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default currentUserData;
