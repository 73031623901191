import {
    SET_ORGANIZATION,
} from "../actions/actionTypes";
import { Organization } from "../types";

const initialState: Organization = {
    physicalAddress: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    mailingAddress: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    isBlocked: false,
    _id: "",
    name: "",
    email: "",
    phone: "",
    dotNumber: "",
    users: [],
    __v: 0,
    trucks: [],
};

export const setOrganization = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ORGANIZATION:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default setOrganization;
