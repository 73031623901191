import React, { FunctionComponent, useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./MapPage.module.css";
import { MapPageFilter } from "./Filter/MapPageFilter";
import MapPageNavbar from "./MapPageNavbar";
import MapComponent from "./MapComponent";
import { manuallyImportedTruckData } from "./Constants";
import TrucksList from "./TrucksList";
import MapPageNavbarMobile from "./Adaptive_Map_Page/MapPageNavbarMobile";
import MapPageSelector from "./MapPageSelector";
import FieldSelector from "./Adaptive_Map_Page/FieldSelector";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { errorToast } from "../../components/Toast/ToastMessage";
import { REACT_APP_PAGINATION_OFFSET, REACT_APP_TRUCK_API_BASE_URL } from '../../utils/UrlConstants';

const MapPage: FunctionComponent = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [trucks, setTrucks] = useState([]);
  const [fullDataCount, setfullDataCount] = useState<number>(0);
  const [offersCount, setOffersCount] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedPrecategory, setSelectedPrecategory] = useState<string>("");
  const [isPortalOpen, setIsPortalOpen] = useState<boolean>(false);
  const [selectedHearts, setSelectedHearts] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [liftGate, setliftGate] = useState<string>("");
  const [bodyType, setbodyType] = useState<string>("");
  const [doorType, setdoorType] = useState<string>("");
  const [length, setlength] = useState<string>("");
  const [transmission, settransmission] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [others, setothers] = useState<string>("");
  const [menufacturer, setmenufacturer] = useState<string>("");
  const [minimumPrice, setMinimumPrice] = useState<number>(0);
  const [maximumPrice, setMaximumPrice] = useState<number>(0);
  const [mileageFrom, setMileageFrom] = useState<number>(0);
  const [mileageTo, setMileageTo] = useState<number>(0);
  const [isFilterReset, setIsFilterReset] = useState<boolean>(false);

  const [showToast, setShowToast] = useState<boolean>(true);
  const [loading, setloading] = useState<boolean>(true);
  const [vehicleType, setVehicleType] = useState<string>("");
  const [vehicleSubType, setVehicleSubType] = useState<string>("");

  //PAGINATION
  const offset: number = parseInt(REACT_APP_PAGINATION_OFFSET || '8');

  const fetchTrucks = async (query: any = null) => {
    setloading(true);
    var url = `${REACT_APP_TRUCK_API_BASE_URL}/trucks?offset=${offset}&page=${currentPage}`;
    if (query != null) {
      url = `${
        REACT_APP_TRUCK_API_BASE_URL
      }/trucks?offset=${offset}&page=${query.page || 1}&${
        query?.filters?.replace(/,/g, "") != "" ? query.filters : ""
      }&priceMin=${minimumPrice != 0 ? minimumPrice : ""}&priceMax=${
        maximumPrice != 0 ? maximumPrice : ""
      }&mileageMin=${mileageFrom != 0 ? mileageFrom : ""}&mileageMax=${
        mileageTo != 0 ? mileageTo : ""
      }`;
    }
    url = url?.replace(/ /g, "")?.replace(/,,/g, "")?.replace(/&&/g, "&");
    url =
      query && query.vehicleSubtype
        ? `${url}&vehicleSubtype=${query.vehicleSubtype}`
        : url;
    url =
      query && query.vehicleType
        ? `${url}&vehicleType=${query.vehicleType}`
        : url;
    try {
      const response = await axios.get(url);
      setTrucks(response.data.trucks);
      setfullDataCount(response.data.recordsFound);

      setloading(false);
      setShowToast(true);
    } catch (error) {
      setTrucks([]);
      setfullDataCount(0);
      setloading(false);
      errorToast("Trucks not found");
      console.error("Error fetching trucks:", error);
    }
  };

  const resetFilters = () => {
    setIsFilterReset(true);
    setVehicleType("");
    setVehicleSubType("");
    fetchTrucks();
  };

  const getSubCatTrucks = (subCat: string) => {
    setVehicleSubType(subCat);
    fetchTrucks({ vehicleSubtype: subCat, vehicleType: vehicleType });
  };

  const getCatTrucks = (cat: string) => {
    setVehicleType(cat);
    fetchTrucks({ vehicleType: cat, vehicleSubtype: vehicleSubType });
  };

  const generateFilterParams = () => {
    const removeSpacesAndCommas = (str: string) =>
      str?.replace(/ /g, "")?.replace(/,,/g, "");
    const buildParam = (paramName: string, paramValue: string) =>
      paramValue ? `&${paramName}=${paramValue}` : "";

    let filters = "";
    filters += buildParam(
      "liftGateType",
      removeSpacesAndCommas(liftGate) == ","
        ? ""
        : removeSpacesAndCommas(liftGate)
    );
    filters += buildParam(
      "trailerType",
      removeSpacesAndCommas(bodyType) == ","
        ? ""
        : removeSpacesAndCommas(bodyType)
    );
    filters += buildParam(
      "cargoSecurementTrack",
      removeSpacesAndCommas(doorType) == ","
        ? ""
        : removeSpacesAndCommas(doorType)
    );
    filters += buildParam(
      "length",
      removeSpacesAndCommas(length) == "," ? "" : removeSpacesAndCommas(length)
    );
    filters += buildParam(
      "transmissionType",
      removeSpacesAndCommas(transmission) == ","
        ? ""
        : removeSpacesAndCommas(transmission)
    );
    filters += buildParam(
      "others",
      removeSpacesAndCommas(others) == "," ? "" : removeSpacesAndCommas(others)
    );
    filters += buildParam(
      "manufacturer",
      removeSpacesAndCommas(menufacturer) == ","
        ? ""
        : removeSpacesAndCommas(menufacturer)
    );

    return filters;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    fetchTrucks();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePassedOffersCount = (count: number) => {
    setOffersCount(count);
  };

  const handleHeadlineClick = () => {
    setIsPortalOpen(!isPortalOpen);
  };

  const handleItemClick = (title: string) => {
    setSelectedItem(title);
  };

  const handlePrecategory = (title: string) => {
    setSelectedPrecategory(title);
  };

  const handleHeartClick = (index: any) => {
    if (selectedHearts.includes(index)) {
      setSelectedHearts(selectedHearts.filter((item: any) => item !== index));
    } else {
      setSelectedHearts([...selectedHearts, index]);
    }
  };

  const closeFilter = () => {
    setIsPortalOpen(false);
    fetchTrucks({ pageSize: "", filters: generateFilterParams() });
  };

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const allTruckData = [...manuallyImportedTruckData];

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    fetchTrucks({ page: page, filters: generateFilterParams() });
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchTrucks({ page: currentPage - 1, filters: generateFilterParams() });
    }
  };

  const handleNextClick = () => {
    if (currentPage < Math.ceil(fullDataCount / offset)) {
      setCurrentPage(currentPage + 1);
      fetchTrucks({ page: currentPage + 1, filters: generateFilterParams() });
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  const startIdx = (currentPage - 1) * offset;

  return (
    <>
      {isPortalOpen && (
        <>
          <MapPageFilter
            closeFilter={closeFilter}
            passedOffersCount={handlePassedOffersCount}
            setliftGate={setliftGate}
            setbodyType={setbodyType}
            setdoorType={setdoorType}
            setlength={setlength}
            settransmission={settransmission}
            setothers={setothers}
            setGoal={setGoal}
            setMinimumPrice={setMinimumPrice}
            setMaximumPrice={setMaximumPrice}
            setmenufacturer={setmenufacturer}
            setMileageFrom={setMileageFrom}
            setMileageTo={setMileageTo}
            setIsFilterReset={setIsFilterReset}
            bodyType={bodyType}
            liftGate={liftGate}
            doorType={doorType}
            length={length}
            transmission={transmission}
            others={others}
            goal={goal}
            minimumPrice={minimumPrice}
            maximumPrice={maximumPrice}
            menufacturer={menufacturer}
            mileageFrom={mileageFrom}
            mileageTo={mileageTo}
            isFilterReset={isFilterReset}
          />
        </>
      )}

      <div className={styles.mapPage} style={{ paddingBottom: "7vh" }}>
        {windowWidth < 850 ? (
          <>
            <FieldSelector
              selectedPrecategory={selectedPrecategory}
              handlePrecategory={handlePrecategory}
              allTruckData={allTruckData}
              selectedItem={selectedItem}
              handleItemClick={handleItemClick}
              handleHeadlineClick={handleHeadlineClick}
            />
          </>
        ) : (
          <div className={styles.emptyDivParent}>
            <div className={styles.frameChild23} />
            <div
              className={styles.rectangleParent}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <MapPageSelector
                selectedPrecategory={selectedPrecategory}
                handlePrecategory={handlePrecategory}
                allTruckData={allTruckData}
                selectedItem={selectedItem}
                handleItemClick={handleItemClick}
                getSubCatTrucks={getSubCatTrucks}
                getCatTrucks={getCatTrucks}
                isFilterReset={isFilterReset}
              />

              <div
                className={`${styles.button11} hoverButtons`}
                onClick={handleHeadlineClick}
              >
                <img
                  className={styles.iconoutlinesearch}
                  alt=""
                  src="/generics/iconoutlinefilter.svg"
                />
                <div className={styles.headline}>
                  <span className={styles.filterSpan}>
                    Filter ({offersCount == 0 ? fullDataCount : 0})
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.frameChild23} />
          </div>
        )}

        <div className={styles.mainContent}>
          <div className={styles.topMap}>
            <MapComponent styles={styles} />
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.frameGroup}>
              <div className={styles.frameDiv}>
                <div className={styles.resultsFound}>
                  {fullDataCount} results found
                </div>
                <div style={{ display: "flex", gap: "1vh" }}>
                  {/* <div className={`${styles.button7} hoverButtons`}>
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/iconoutlinebell1.svg"
                    />
                    <div className={styles.headline}>Follow the filter</div>
                  </div> */}
                  <div className={`${styles.button7} hoverButtons`}>
                    <div className={styles.headline}>Sort by</div>
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/iconsolidchevrondown.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className={`${styles.vecicleCardGroup}`}>
                <TrucksList
                  trucks={trucks}
                  startIdx={startIdx}
                  styles={styles}
                  handleHeartClick={handleHeartClick}
                  selectedHearts={selectedHearts}
                />
              </div>
            )}

            {showToast && (
              <>
                <div className={styles.frameChild14} />
                <div className={styles.toast}>
                  <div className={styles.iconoutlinetruckParent2}>
                    <img
                      className={styles.iconoutlinebell}
                      alt=""
                      src="/generics/iconoutlinetruck2.svg"
                    />
                    <div className={styles.moreDistantButNoLessImpoParent}>
                      <div className={styles.tandemTractor}>
                        More distant, but not less important
                      </div>
                      <div className={styles.clearTheFilter}>
                        Clear the filter to view all transport options
                      </div>
                    </div>
                  </div>
                  <div className={styles.filterContainer}>
                    <div
                      className={`${styles.button10}  hoverButtons`}
                      onClick={resetFilters}
                    >
                      <div className={styles.headline}>Clear filters</div>
                    </div>
                    <CancelIcon onClick={hideToast} />
                  </div>
                </div>
              </>
            )}
            {/* <div className={styles.resultsFound1}>20 results found</div> */}
            <div className={styles.centeredPageNumbersdesktop}>
              <div className={styles.topBorder} />
              <div className={styles.links}>
                <div className={styles.moreDistantButNoLessImpoParent}>
                  <div className={styles.topBorder1} />
                  <div
                    className={`${styles.content} hoverButtons`}
                    onClick={handlePreviousClick}
                  >
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/arrow-narrow-left1.svg"
                    />
                    <div className={`${styles.previous} `}>Previous</div>
                  </div>
                </div>
                <div className={styles.numberLinks}>
                  {[...Array(Math.ceil(fullDataCount / offset))].map(
                    (_, index) => (
                      <div
                        key={index}
                        className={`${styles.moreDistantButNoLessImpoParent} ${
                          currentPage === index + 1 ? styles.activePage : ""
                        }`}
                        onClick={() => handlePageClick(index + 1)}
                      >
                        <div
                          className={
                            styles[
                              `topBorder${currentPage === index + 1 ? 3 : 1}`
                            ]
                          }
                        />
                        <div className={styles.content1}>
                          <div
                            className={`${styles.previous} hoverButtons`}
                            style={{
                              color:
                                currentPage === index + 1
                                  ? "#fb814c"
                                  : "inherit",
                            }}
                          >
                            {index + 1}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className={styles.moreDistantButNoLessImpoParent}>
                  <div className={styles.topBorder1} />
                  <div
                    className={`${styles.content8} hoverButtons`}
                    onClick={handleNextClick}
                  >
                    <div className={`${styles.previous} hoverButtons`}>
                      Next
                    </div>
                    <img
                      className={styles.iconoutlinesearch}
                      alt=""
                      src="/generics/arrow-narrow-right1.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomMap}>
            <MapComponent styles={styles} id={styles.bottomMap} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapPage;
