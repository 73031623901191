import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { RegistrationData } from "../store/types";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./RegistrationInitialPage.module.css";
import VechicleToRent from "../components/Registration/VechicleToRent";
import AddOrganization from "../components/Registration/AddOrganization";
import AddOrganizationDetails from "../components/Registration/AddOrganizationDetails";
import AddOrganizationDetails2 from "../components/Registration/AddOrganizationDetails2";
import AddOrganizationList from "../components/Registration/AddOrganizationList";
import AddOrganizationVerification from "../components/Registration/AddOrganizationVerification";
import AddOrganizationVerification2 from "../components/Registration/AddOrganizationVerification2";
import AddOrganizationFinal from "../components/Registration/AddOrganizationFinal";
import SideAndNavbarDriver from "../components/Registration_Generics/SideAndNavbarDriver";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/types";
import { updateRegistrationData as handleUpdateRegistrationData } from "../store/actions/registrationActions";
import RegisterationSideImage1 from "../components/Registration/RegisterationSideImage1";

type OwnProps = {
  registrationData: RegistrationData;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
};

interface RegistrationInitialPageProps {
  registrationData: RegistrationData;
  handleUpdateRegistrationData: (data: RegistrationData) => void;
}

const mapStateToProps = (state: RootState) => ({
  registrationData: state.registrationData,
});

const mapDispatchToProps = {
  handleUpdateRegistrationData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const RegistrationInitialPage: FunctionComponent<OwnProps & PropsFromRedux> = ({
  registrationData,
  handleUpdateRegistrationData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onTheLogoMakerBuiltFor1000Click = useCallback(() => {}, []);

  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNextButtonClick = (step?: number) => {
    if (step !== undefined) {
      setCurrentStep(step);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackButtonClick = () => {
    if (currentStep === 1) {
      navigate("/registration_driver");
    }
    setCurrentStep(currentStep - 1);
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <SideAndNavbarDriver />
            <VechicleToRent
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 2:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganization
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={() => {}}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
              // onChangeRouteNextButtonClick={() => handleNextButtonClick(2.1)}
            />
          </>
        );
      case 3:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganizationList
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onSkipButtonClick={() => handleNextButtonClick(4)}
              handleUpdateRegistrationData={handleUpdateRegistrationData}
              registerData={registrationData}
            />
          </>
        );
      case 4:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganizationDetails2
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
              registrationData={registrationData}
              updateRegistrationData={handleUpdateRegistrationData}
            />
          </>
        );
      case 5:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganizationVerification
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
          </>
        );
      case 6:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganizationVerification2
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
              onSkipBackwards={() => handleNextButtonClick(4)}
            />
          </>
        );
      case 7:
        return (
          <>
            <SideAndNavbarDriver />
            <AddOrganizationFinal
              onNextButtonKeyDown={() => {}}
              role="button"
              onClick={handleNextButtonClick}
              onNextButtonClick={handleNextButtonClick}
              onBackButtonClick={handleBackButtonClick}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.RegistrationInitialPage}>
      <RegisterationSideImage1 styles={styles} />

      <div className={styles.RegistrationInitialPageChild} />

      {renderCurrentStep()}
    </div>
  );
};

export default connector(RegistrationInitialPage);
