import React from "react";

const RegisterationSideImage1 = (props: any) => {
  return (
    <div>
      <div className={props.styles.celesClubPVidiGruzovikhMaParent}>
        <img
          className={props.styles.celesClubPVidiGruzovikhMaIcon}
          alt=""
          src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
        />
        <img
          className={props.styles.stockPhotoBigRigGrayBonneIcon}
          alt=""
          src="/generics/stockphotobigriggraybonnetsemitruckwithhighcabandlongrefrigeratorsemitrailertransporting2263359175removebgpreview@2x.png"
        />
        <div className={props.styles.inputTextParent}>
          <div
            className={props.styles.inputText}
            style={{ textAlign: "left", fontSize: "18px" }}
          >
            Hire internationally with complete confidence
          </div>
          <div
            className={props.styles.inputText1}
            style={{ textAlign: "left" }}
          >
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterationSideImage1;
