import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/AddTruckSpecs.module.css";
import styles2 from "../../css/Registration_Driver/AddOrganization.module.css";
import styled from "styled-components";
import styles3 from "./css/AddTruckTypeAndDetails.module.css";
import { ConnectedProps, connect, useSelector } from "react-redux";
import { RootStateTruckAdd, TruckRegistrationData } from "@/src/store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { lengthOptions, cargoOptions } from './selectValues'
import { errorToast } from "../../components/Toast/ToastMessage";

export type PropsFromReduxAddTruckSpecs = ConnectedProps<
  typeof connectorAddTruckSpecs
>;

export type AddTruckSpecs = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  idToUpdate?: TruckRegistrationData;
  doorType: string;
  setDoorType: React.Dispatch<React.SetStateAction<string>>;
  liftGateType: string;
  setLiftGateType: React.Dispatch<React.SetStateAction<string>>;
  dockHeight: string;
  setDockHeight: React.Dispatch<React.SetStateAction<string>>;
  allowTravelOutOfState: string;
  setAllowTravelOutOfState: React.Dispatch<React.SetStateAction<string>>;
  gps: string;
  setGps: React.Dispatch<React.SetStateAction<string>>;
  chassis: string;
  setChassis: React.Dispatch<React.SetStateAction<string>>;
  transmissionType: string;
  setTransmissionType: React.Dispatch<React.SetStateAction<string>>;
  lengthValue: string;
  setLengthValue: React.Dispatch<React.SetStateAction<string>>;
  cargoValue: string;
  setCargoValue: React.Dispatch<React.SetStateAction<string>>;
  cargoSecurementTrack: string;
  setCargoSecurementTrack: React.Dispatch<React.SetStateAction<string>>;
} & PropsFromReduxAddTruckSpecs;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckSpecs = connect(mapStateToProps, mapDispatchToProps);

const AddTruckSpecs: FunctionComponent<AddTruckSpecs> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  idToUpdate,
  doorType,
  setDoorType,
  liftGateType,
  setLiftGateType,
  dockHeight,
  setDockHeight,
  allowTravelOutOfState,
  setAllowTravelOutOfState,
  gps,
  setGps,
  chassis,
  setChassis,
  transmissionType,
  setTransmissionType,
  lengthValue,
  setLengthValue,
  cargoValue,
  setCargoValue,
  cargoSecurementTrack,
  setCargoSecurementTrack,
}) => {
  const handleNextClick = () => {
    if(validateForm()){
      onNextButtonClick();
    }else {
      errorToast("Please fill all fields");
    }
  };

  const navigate = useNavigate();

  const truckAddRegistrationDataRedux = useSelector(
    (state: RootStateTruckAdd) => state
  );
  const [requiredField, setRequiredField] = useState<string>("");

  const handleDoorType = (state: string) => {
    setDoorType(state);
    const newDoorType = state;
    // setDoorType(newDoorType);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      doorType: newDoorType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLiftGateType = (state: string) => {
    setLiftGateType(state);
    const newLiftGateType = state;
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      liftGateType: newLiftGateType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleDockHeight = (state: string) => {
    setDockHeight(state);
    const newDockHeight = state;
    setDockHeight(newDockHeight);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      dockHeight: newDockHeight,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleAllowTravelOutOfState = (state: string) => {
    setAllowTravelOutOfState(state);
    const newAllowTravelOutOfState = state;
    setAllowTravelOutOfState(newAllowTravelOutOfState);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      allowTravelOutOfState: newAllowTravelOutOfState,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleGps = (state: string) => {
    setGps(state);
    const newGps = state;
    setGps(newGps);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      gps: newGps,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleChassis = (state: string) => {
    setChassis(state);
    const newChassis = state;
    setChassis(newChassis);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      chassis: newChassis,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTransmissionType = (state: string) => {
    setTransmissionType(state);
    const newTransmissionType = state;
    setTransmissionType(newTransmissionType);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      transmissionType: newTransmissionType,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const [isDropdownOpen1, setIsDropdownOpen1] = useState<boolean>(false);
  const [isCargoDropdown, setIsCargoDropdown] = useState<boolean>(false);

  const toggleDropdown = () => {
    closeDropDowns();
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const toggleCargoDropdown = () => {
    closeDropDowns();
    setIsCargoDropdown(!isCargoDropdown);
  };

  const closeDropDowns = () => {
    setIsDropdownOpen1(false);
    setIsCargoDropdown(false);
  }

  const filteredOptions = lengthOptions.filter((option) =>
    option.toLowerCase().includes(lengthValue.toLowerCase())
  );

  const filteredCargo = cargoOptions.filter((option) =>
    option.toLowerCase().includes(cargoValue.toLowerCase())
  );

  const handleLength = (option: string) => {
    setIsDropdownOpen1(!isDropdownOpen1);
    const newLength = option;
    setLengthValue(newLength);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      length: newLength,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleCargoValue = (option: string) => {
    setIsCargoDropdown(!isCargoDropdown);
    const newCargo = option;
    setCargoSecurementTrack(newCargo);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      cargoSecurementTrack: newCargo,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLengthValue(value);
    setIsDropdownOpen1(true);
  };

  const handleCargoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCargoSecurementTrack(value);
    setIsCargoDropdown(true);
  };

  const onInputTextClick = useCallback(() => {
    navigate("/register-a-truck-or-edit-1-page-for-user");
  }, [navigate]);

  const validateForm = () => {
    if (!idToUpdate && (!doorType || !liftGateType || !lengthValue || !cargoSecurementTrack || !dockHeight || !allowTravelOutOfState || !gps || !transmissionType || !chassis )) {
      const emptyField = 
      !doorType
      ? "doorType"
      : !liftGateType
      ? "liftGateType"
      : !lengthValue
      ? "lengthValue"
      : !cargoSecurementTrack
      ? "cargoSecurementTrack"
      : !dockHeight
      ? "dockHeight"
      : !allowTravelOutOfState
      ? "allowTravelOutOfState"
      : !gps
      ? "gps"
      : !transmissionType
      ? "transmissionType"
      : !chassis
      ? "chassis"
      : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={styles.registerATruckOrEdit3P}>
        <div className={styles.registerATruckOrEdit3PChild} />
        <div className={styles.component35Parent}>
          <div className={styles.component35}>
            <div className={styles.component35Inner}>
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.text2}>Back</div>
                </div>
                <div className={styles.inputText} onClick={onInputTextClick}>
                  3 of 6
                </div>
              </div>
            </div>
            <div className={styles.userNameParent}>
              <div className={styles.userName} onClick={() => {}}>
                Add Your Specs
              </div>
              <div className={styles.inputText1}>
                Sign up with your work Google account or use the form.
              </div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.textButtonGroup}>
              <div className={styles.textButton1}>
                <img className={styles.icon1} alt="" src="/generics/icon.svg" />
                <div className={styles.text3}>Back&nbsp;</div>
              </div>
              <div className={styles.frameGroup}>
                <div className={styles.inputWithLabelParent}>
                  <div className={styles.inputWithLabel}>
                    <div className={styles.inputText2} style={{color: requiredField == 'doorType' ? 'red' : '' }} >Door type*</div>
                    <div className={styles.basicInput}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagParent}>
                    <div
                      className={`${styles.tag} ${
                        doorType === "swing" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleDoorType("swing")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer}>Swing</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        doorType === "roll" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleDoorType("roll")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl1}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer1}>Roll</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelGroup}>
                  <div className={styles.inputWithLabel1}>
                    <div className={styles.inputText2} style={{color: requiredField == 'liftGateType' ? 'red' : '' }}>Lift gate*</div>
                    <div className={styles.basicInput1}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagGroup}>
                    <div
                      className={`${styles.tag} ${
                        liftGateType.includes("rail-gate") ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleLiftGateType("rail-gate")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl2}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer2}>Rail gate</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        liftGateType.includes("truck-under") ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleLiftGateType("truck-under")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl3}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer3}>Truck under</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        liftGateType.includes('no-lift-gate')
                          ? styles.selectedTag
                          : ""
                      } hoverButtons`}
                      onClick={() => handleLiftGateType("no-lift-gate")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl4}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer4}>No lift gate</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelContainer}>
                  <div className={styles.inputWithLabel2}>
                    <div className={styles.inputText2}>Length*</div>
                    <div className={styles.dropdown1}
                      onClick={toggleDropdown}
                      style={{border: requiredField == 'lengthValue' ? '1px solid red' : '' }}
                    >
                      <input
                        className={''}
                        placeholder="Length"
                        type="text"
                        value={lengthValue || idToUpdate?.length}
                        onChange={handleLengthChange}
                      />

                      <StyledButton
                        className={styles2.dropdownButton}
                        onKeyDown={() => {}}
                        role="button"
                        style={{ position: "absolute", top: "1vh" }}
                      >
                        <FontAwesomeIcon key={"1"} icon={faChevronDown} />
                      </StyledButton>
                      {isDropdownOpen1 && (
                        <Container>
                          {filteredOptions.map((option, index) => (
                            <Item
                              key={index}
                              className={`hoverDropdown ${lengthValue == option ? 'text-theme' : '' } `}
                              onClick={() => handleLength(option)}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      )}
                    </div>
                  </div>
                  <div className={styles.inputWithLabel3}>
                    <div className={styles.inputText2}>
                      Cargo Securement track
                    </div>
                    <div className={styles.dropdown1}
                      onClick={toggleCargoDropdown}
                      style={{border: requiredField == 'cargoSecurementTrack' ? '1px solid red' : '' }}
                    >
                      <input
                        className={''}
                        placeholder="Cargo Securement track"
                        type="text"
                        value={cargoSecurementTrack || idToUpdate?.cargoSecurementTrack}
                        onChange={handleCargoChange}
                      />

                      <StyledButton
                        className={styles2.dropdownButton}
                        onKeyDown={() => {}}
                        role="button"
                        style={{ position: "absolute", top: "1vh" }}
                      >
                        <FontAwesomeIcon key={"2"} icon={faChevronDown} />
                      </StyledButton>
                      {isCargoDropdown && (
                        <Container>
                          {filteredCargo.map((option, index) => (
                            <Item
                              key={index}
                              className={`hoverDropdown ${cargoSecurementTrack == option ? 'text-theme' : '' } `}
                              onClick={() => handleCargoValue(option)}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameChild} />
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <div className={styles.inputWithLabel4}>
                    <div className={styles.inputText2} style={{color: requiredField == 'dockHeight' ? 'red' : '' }}>Dock Height*</div>
                    <div className={styles.basicInput2}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagContainer}>
                    <div
                      className={`${styles.tag} ${
                        dockHeight === "yes" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleDockHeight("yes")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl5}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer5}>Yes</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        dockHeight === "no" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleDockHeight("no")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl6}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer6}>No</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent1}>
                  <div className={styles.inputWithLabel5}>
                    <div className={styles.inputText2} style={{color: requiredField == 'allowTravelOutOfState' ? 'red' : '' }}>
                      Renter can travel out of state*
                    </div>
                    <div className={styles.basicInput3}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagParent1}>
                    <div
                      className={`${styles.tag} ${
                        allowTravelOutOfState === "yes"
                          ? styles.selectedTag
                          : ""
                      } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState("yes")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl7}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer7}>Yes</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        allowTravelOutOfState === "no" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleAllowTravelOutOfState("no")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl8}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer8}>No</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent2}>
                  <div className={styles.inputWithLabel6}>
                    <div className={styles.inputText2} style={{color: requiredField == 'gps' ? 'red' : '' }}>Gps</div>
                    <div className={styles.basicInput4}>
                      <div className={styles.inputText2}>First name&nbsp;</div>
                    </div>
                  </div>
                  <div className={styles.tagParent2}>
                    <div
                      className={`${styles.tag} ${
                        gps === "yes" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleGps("yes")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl9}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer9}>Yes</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        gps === "no" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleGps("no")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl10}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer10}>No</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent3}>
                  <div className={styles.inputWithLabel7}>
                    <div className={styles.inputText2} style={{color: requiredField == 'transmissionType' ? 'red' : '' }}>TransmissionType</div>
                  </div>
                  <div className={styles.tagParent3}>
                    <div
                      className={`${styles.tag} ${
                        transmissionType === "automatic"
                          ? styles.selectedTag
                          : ""
                      } hoverButtons`}
                      onClick={() => handleTransmissionType("automatic")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl11}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer11}>Automatic</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        transmissionType === "manual" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleTransmissionType("manual")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl12}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer12}>Manual</div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputWithLabelParent4}>
                  <div className={styles.inputWithLabel8}>
                    <div className={styles.inputText2} style={{color: requiredField == 'chassis' ? 'red' : '' }} >
                      Chassis (For trailers)
                    </div>
                  </div>
                  <div className={styles.tagParent4}>
                    <div
                      className={`${styles.tag} ${
                        chassis === "2x2" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleChassis("2x2")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl13}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer13}>2x2</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        chassis === "2x4" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleChassis("2x4")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl14}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer14}>2x4</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        chassis === "2x6" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleChassis("2x6")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl15}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer15}>2x6</div>
                    </div>
                    <div
                      className={`${styles.tag} ${
                        chassis === "6x4" ? styles.selectedTag : ""
                      } hoverButtons`}
                      onClick={() => handleChassis("6x4")}
                    >
                      <img
                        className={styles.earnRevenueFromYourIdleFl16}
                        alt=""
                        src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                      />
                      <div className={styles.reefer16}>6x4</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.button4}>
                <div className={styles.text4}>Save as a draft</div>
              </div>
              <div className={styles.buttonParent1}>
                <div
                  className={`${styles.button5} hoverButtons`}
                  onClick={onBackButtonClick}
                >
                  <div className={styles.text5}>Back</div>
                </div>
                <div
                  className={`${styles.button6} hoverButtons`}
                  onClick={handleNextClick}
                >
                  <div className={styles.text6}>Next</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.celesClubPVidiGruzovikhMaParent}>
          <img
            className={styles.celesClubPVidiGruzovikhMaIcon}
            alt=""
            src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
          />
          <div className={styles.inputTextContainer}>
            <div className={styles.inputText2}>
              Hire internationally with complete confidence
            </div>
            <div className={styles.inputText2}>
              We'll handle your worldwide compliance, payroll, and benefits, so
              you can fast-track your international expansion.
            </div>
          </div>
          <img
            className={styles.stockPhotoBigRigGrayBonneIcon}
            alt=""
            src="/generics/stockphotobigriggraybonnetsemitruckwithhighcabandlongrefrigeratorsemitrailertransporting2263359175removebgpreview@2x.png"
          />
          <img
            className={styles.theLogoMakerBuiltFor10001}
            alt=""
            src="/logos/Truck4 logo-1.png"
            onClick={() => {
              window.location.href = "/#/main";
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connectorAddTruckSpecs(AddTruckSpecs);

const StyledButton = styled.button`
  border: 0;
  right: 5%;
  background-color: transparent;
`;

const Container = styled.div`
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 24vh;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: var(--neutral-400, #94a3b8);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  margin-top: 4px;
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;

const Item = styled.div`
  text-align: left;
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
