import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./css/AddTruckPrice.module.css";
import { RootStateTruckAdd, TruckRegistrationData } from "../../store/types";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { connect, useSelector } from "react-redux";
import { PropsFromReduxAddTruckToRent } from "./AddTruckApplyToRent";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import axios from "axios";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_ORG_API_BASE_URL, REACT_APP_TRUCK_API_BASE_URL } from '../../utils/UrlConstants';

export type AddTruckPriceProps = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  setCostRentPerDay: (value: number) => void;
  setPrepaidMileage: (value: number | string) => void;
  setLeaseCostRentPerDay: (value: number) => void;
  setLeasePrepaidMileage: (value: number | string) => void;
  setDeposite: (value: number) => void;
  setEquipementPrice: (value: number) => void;
  setInsurrancePrice: (value: number) => void;
  setTaxes: (value: number) => void;
  setEnableForLease: (value: boolean) => void;
  setEnableForRent: (value: boolean) => void;
  costRentPerDay: number;
  prepaidMileage: number | string;
  leaseCostRentPerDay: number;
  leasePrepaidMileage: number | string;
  deposite: number;
  equipementPrice: number;
  insurrancePrice: number;
  taxes: number;
  enableForLease: boolean;
  enableForRent: boolean;
  idToUpdate?: TruckRegistrationData;
} & PropsFromReduxAddTruckToRent;

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckPrice = connect(mapStateToProps, mapDispatchToProps);

const AddTruckPrice: FunctionComponent<AddTruckPriceProps> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  setCostRentPerDay,
  setPrepaidMileage,
  setLeaseCostRentPerDay,
  setLeasePrepaidMileage,
  setDeposite,
  setEquipementPrice,
  setInsurrancePrice,
  setTaxes,
  setEnableForLease,
  setEnableForRent,
  costRentPerDay,
  prepaidMileage,
  leaseCostRentPerDay,
  leasePrepaidMileage,
  deposite,
  equipementPrice,
  insurrancePrice,
  taxes,
  enableForLease,
  enableForRent,
  idToUpdate,
}) => {
  const currentUser = useSelector((state: any) => state.currentUser);
  const navigate = useNavigate();
  const [requiredField, setRequiredField] = useState<string>("");
  const truckAddRegistrationDataRedux = useSelector((state: any) => state.truckAddRegistrationData);
  const [organizationId, setorganizationId] = useState(currentUser.organizations[0]);
  const [organizationName, setOrganizationName] = useState<string>("");
  const [orgDot, setOrgDot] = useState<string>("");

  const fetchOrganization = async (id: string) => {
    const url = `${REACT_APP_ORG_API_BASE_URL}/organization/${id}`;
    try {
      const response = await axios.get(url);
      console.log(response.data.organization);
      setOrganizationName(response.data.organization.name);
      setOrgDot(response.data.organization.dotNumber);

      const updatedRegistrationData: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        organization: {
          name: response.data.organization.name,
          dotNumber: response.data.organization.dotNumber,
        },
      };

      updateTruckRegistrationData(updatedRegistrationData);
    } catch (error) {
      errorToast("Error fetching organization");
      console.error("Error fetching organization:", error);
    }
  };

  useEffect(() => {
    fetchOrganization(organizationId);
    setEnableForLease(idToUpdate?.leasePerDay ? true : false);
    setEnableForRent(idToUpdate?.rentPerDay ? true : false);
  }, []);

  const handleNextClick = async () => {
    if (!validateForm()) {
      errorToast("Please fill out all fields");
      return;
    }

    if (!idToUpdate) {
      try {
        const response = await fetch(
          `${REACT_APP_TRUCK_API_BASE_URL}/truck/register`,
          {
            method: "POST",
            headers: getAuthHeaders(),
            body: JSON.stringify(
              truckAddRegistrationDataRedux
            ),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          console.log(data);
          errorToast(`Failed to upload: ${data.error}`);
          return;
        }

        onNextButtonClick();
        successToast("Registered successfully");
      } catch (error: any) {
        errorToast(`${error.message}`);
      }
    } else {
      try {
        const updatedData = { ...idToUpdate };
        delete updatedData._id;
        delete updatedData.__v;
        const response = await fetch(
          `${REACT_APP_TRUCK_API_BASE_URL}/truck/${idToUpdate._id}`,
          {
            method: "PUT",
            headers: getAuthHeaders(),
            body: JSON.stringify({
              plateNumber:
                truckAddRegistrationDataRedux
                  .plateNumber || idToUpdate.plateNumber,
              plateState:
                truckAddRegistrationDataRedux
                  .plateState || idToUpdate.plateState,
              VIN:
                truckAddRegistrationDataRedux.VIN ||
                idToUpdate.VIN,
              vehicleType:
                truckAddRegistrationDataRedux
                  .vehicleType || idToUpdate.vehicleType,
              vehicleSubtype:
                truckAddRegistrationDataRedux
                  .vehicleSubtype || idToUpdate.vehicleSubtype,
              manufacturer:
                truckAddRegistrationDataRedux
                  .manufacturer || idToUpdate.manufacturer,
              model:
                truckAddRegistrationDataRedux.model ||
                idToUpdate.model,
              year:
                truckAddRegistrationDataRedux.year ||
                idToUpdate.year,
              mileage:
                truckAddRegistrationDataRedux
                  .mileage || idToUpdate.mileage,
              doorType:
                truckAddRegistrationDataRedux
                  .doorType || idToUpdate.doorType,
              liftGateType:
                truckAddRegistrationDataRedux
                  .liftGateType || idToUpdate.liftGateType,
              length:
                truckAddRegistrationDataRedux.length ||
                idToUpdate.length,
              cargoSecurementTrack:
                truckAddRegistrationDataRedux
                  .cargoSecurementTrack || idToUpdate.cargoSecurementTrack,
              dockHeight:
                truckAddRegistrationDataRedux
                  .dockHeight || idToUpdate.dockHeight,
              allowTravelOutOfState:
                truckAddRegistrationDataRedux
                  .allowTravelOutOfState || idToUpdate.allowTravelOutOfState,
              transmissionType:
                truckAddRegistrationDataRedux
                  .transmissionType || idToUpdate.transmissionType,
              chassis:
                truckAddRegistrationDataRedux
                  .chassis || idToUpdate.chassis,
              images: {
                threeQuarterView:
                  truckAddRegistrationDataRedux.images
                    .threeQuarterView || idToUpdate.images.threeQuarterView,
                front:
                  truckAddRegistrationDataRedux.images
                    .front || idToUpdate.images.front,
                passengerSide:
                  truckAddRegistrationDataRedux.images
                    .passengerSide || idToUpdate.images.passengerSide,
                back:
                  truckAddRegistrationDataRedux.images
                    .back || idToUpdate.images.back,
              },
              leasePrepaidMileage:
                truckAddRegistrationDataRedux
                  .leasePrepaidMileage ||
                (enableForLease && idToUpdate.leasePrepaidMileage),
              prepaidMileage:
                truckAddRegistrationDataRedux
                  .prepaidMileage || idToUpdate.rentPrepaidMileage,
              leasePerDay:
                truckAddRegistrationDataRedux
                  .leasePerDay ||
                (enableForLease && idToUpdate.leasePerDay),
              enableForLease:
                truckAddRegistrationDataRedux
                  .enableForLease,
              enableForRent:
                truckAddRegistrationDataRedux
                  .enableForRent,
              rentPerDay:
                truckAddRegistrationDataRedux
                  .rentPerDay ||
                (enableForRent && idToUpdate.rentPerDay),
              rentPrepaidMileage:
                truckAddRegistrationDataRedux
                  .rentPrepaidMileage ||
                (enableForRent && idToUpdate.rentPrepaidMileage),
              deposite:
                truckAddRegistrationDataRedux
                  .deposite || idToUpdate.deposit,
              equipementPrice:
                truckAddRegistrationDataRedux
                  .equipementPrice || idToUpdate.equipmentPrice,
              insurrancePrice:
                truckAddRegistrationDataRedux
                  .insurrancePrice || idToUpdate.insurancePrice,
              taxes:
                truckAddRegistrationDataRedux.taxes ||
                idToUpdate.taxes,
              files: {
                technicalInspectionFile:
                  truckAddRegistrationDataRedux.files
                    .technicalInspectionFile ||
                  idToUpdate.files.technicalInspectionFile,
                vehicleInsuranceFile:
                  truckAddRegistrationDataRedux.files
                    .vehicleInsuranceFile ||
                  idToUpdate.files.vehicleInsuranceFile,
                vehicleRegistrationFile:
                  truckAddRegistrationDataRedux.files
                    .vehicleRegistrationFile ||
                  idToUpdate.files.vehicleRegistrationFile,
              },
            }),
          }
        );
        if (!response.ok) {
          errorToast("Failed to update");
          return;
        }
        successToast("Truck updated successfully");
        navigate("/admin");
      } catch (error: any) {
        errorToast(`${error.message}`);
      }
    }
  };

  const validateForm = () => {
    if (
      (enableForRent && !costRentPerDay) ||
      (enableForRent && !prepaidMileage) ||
      (enableForLease && !leaseCostRentPerDay) ||
      (enableForLease && !leasePrepaidMileage) ||
      !deposite ||
      !equipementPrice ||
      !insurrancePrice ||
      !taxes
    ) {
      const emptyField =
        enableForRent && !costRentPerDay
          ? "costRentPerDay"
          : enableForRent && !prepaidMileage
          ? "prepaidMileage"
          : enableForLease && !leaseCostRentPerDay
          ? "leaseCostRentPerDay"
          : enableForLease && !leasePrepaidMileage
          ? "leasePrepaidMileage"
          : !deposite
          ? "deposite"
          : !equipementPrice
          ? "equipementPrice"
          : !insurrancePrice
          ? "insurrancePrice"
          : !taxes
          ? "taxes"
          : "";
      setRequiredField(emptyField);
      return false;
    }
    return true;
  };

  const handleCostRentPerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setCostRentPerDay(0);
      return;
    }
    const newCostRentPerDay = parseFloat(e.target.value);
    setCostRentPerDay(newCostRentPerDay);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      rentPerDay: newCostRentPerDay,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handlePrepaidMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const numberValue = parseFloat(inputValue);

    if (
      inputValue === "" ||
      (!isNaN(numberValue) &&
        Number(numberValue) >= 0 &&
        Number(numberValue) <= 1)
    ) {
      setPrepaidMileage(numberValue);
    }

    if (Number(numberValue) > 1) {
      errorToast("Maximum Prepaid Mileage must be 1 or lower");
      setPrepaidMileage(1);
    }

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      rentPrepaidMileage: numberValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseCostPerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setLeaseCostRentPerDay(0);
      return;
    }
    const newLLeaseCostDay = parseFloat(e.target.value);
    setLeaseCostRentPerDay(newLLeaseCostDay);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      leasePerDay: newLLeaseCostDay,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleLeaseMileage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const numberValue = parseFloat(inputValue);

    if (
      inputValue === "" ||
      (!isNaN(numberValue) &&
        Number(numberValue) >= 0 &&
        Number(numberValue) <= 1)
    ) {
      setLeasePrepaidMileage(numberValue);
    }

    if (Number(numberValue) > 1) {
      setLeasePrepaidMileage(1);
      errorToast("Maximum Prepaid Mileage must be 1 or lower");
      return;
    }

    setLeasePrepaidMileage(numberValue);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      leasePrepaidMileage: numberValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleDeposite = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setDeposite(0);
      return;
    }
    const newDeposite = parseFloat(e.target.value);
    setDeposite(newDeposite);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      deposit: newDeposite,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEquipementPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setEquipementPrice(0);
      return;
    }
    const newEquipement = parseFloat(e.target.value);
    setEquipementPrice(newEquipement);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      equipmentPrice: newEquipement,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleInsurrancePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setInsurrancePrice(0);
      return;
    }
    const newInsurrance = parseFloat(e.target.value);
    setInsurrancePrice(newInsurrance);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      insurancePrice: newInsurrance,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleTaxes = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      setTaxes(0);
      return;
    }
    const newTaxes = parseFloat(e.target.value);
    setTaxes(newTaxes);

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      taxes: newTaxes,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEnableForRent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rentToggle = e.target.checked;
    setEnableForRent(rentToggle);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      enableForRent: rentToggle,
    };

    if (!rentToggle) {
      setCostRentPerDay(0);
      setPrepaidMileage(0);
      const updatedRentValues: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        rentPerDay: 0,
        rentPrepaidMileage: 0,
      };
      updateTruckRegistrationData(updatedRentValues);
    }

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleEnableForLease = (e: React.ChangeEvent<HTMLInputElement>) => {
    const leaseToggle = e.target.checked;
    setEnableForLease(leaseToggle);
    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      enableForLease: leaseToggle,
    };

    if (!leaseToggle) {
      setLeaseCostRentPerDay(0);
      setLeasePrepaidMileage(0);
      const updatedLeaseValues: Partial<TruckRegistrationData> = {
        ...truckAddRegistrationData,
        leasePerDay: 0,
        leasePrepaidMileage: 0,
      };
      updateTruckRegistrationData(updatedLeaseValues);
    }

    updateTruckRegistrationData(updatedRegistrationData);
  };

  return (
    <>
      <div className={styles.registerATruckOrEdit6P}>
        <div className={styles.registerATruckOrEdit6PChild} />
        <div className={styles.component35Parent}>
          <div className={styles.component35}>
            <div className={styles.component35Inner}>
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.text2}>Back</div>
                </div>
                <div className={styles.inputText}>6 of 6</div>
              </div>
            </div>
            <div className={styles.userNameParent}>
              <div className={styles.userName}>Setting the price</div>
              <div className={styles.inputText1}>
                Sign up with your work Google account or use the form.
              </div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.textButtonGroup}>
              <div className={styles.textButton1}>
                <img className={styles.icon1} alt="" src="/generics/icon.svg" />
                <div className={styles.text3}>Back&nbsp;</div>
              </div>
              <div className={styles.inputWithLabelParent}>
                <div className={styles.inputWithLabel}>
                  <div className={styles.inputText2}>For rent</div>
                  <div className={styles.inputTextParent}>
                    <div className={styles.inputText3}>
                      People can rent the truck based on the stipulated period.
                    </div>
                    <div className={styles.toggle}>
                      <input
                        className={styles.checkbox2}
                        type="checkbox"
                        style={{
                          height: "inherit",
                          width: "100%",
                          border: "0",
                          boxShadow: "0px 0px 3px inset",
                        }}
                        onChange={handleEnableForRent}
                        checked={
                          enableForRent ||
                          (idToUpdate?.enableForRent && enableForRent)
                        }
                      />
                      <div className={styles.on}>On</div>
                    </div>
                  </div>
                </div>
                {(enableForRent ||
                  (idToUpdate?.enableForRent && enableForRent)) && (
                  <>
                    <div className={styles.inputWithLabelGroup}>
                      <div className={styles.inputWithLabel1}>
                        <div className={styles.inputText4}>
                          Cost per rent per day
                        </div>
                        <div
                          className={styles.basicInput}
                          style={{
                            border:
                              requiredField == "costRentPerDay"
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <div className={styles.inputLeftContent}>
                            <img
                              className={styles.iconoutlinecurrencyDollar}
                              alt=""
                              src="/generics/iconoutlinecurrencydollar.svg"
                            />
                            <input
                              type="text"
                              className={styles.inputText5}
                              placeholder="Cost per rent per day"
                              style={{ border: "none" }}
                              value={
                                costRentPerDay ||
                                (idToUpdate?.rentPerDay &&
                                isNaN(Number(costRentPerDay))
                                  ? idToUpdate?.rentPerDay
                                  : "")
                              }
                              onChange={handleCostRentPerDay}
                              maxLength={25}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputWithLabel2}>
                        <div className={styles.inputText6}>Prepaid Mileage</div>
                        <div
                          className={styles.basicInput1}
                          style={{
                            border:
                              requiredField == "prepaidMileage"
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <div className={styles.inputLeftContent1}>
                            <img
                              className={styles.iconoutlinecurrencyDollar1}
                              alt=""
                              src="/generics/iconoutlinecurrencydollar.svg"
                            />
                            <input
                              type="number"
                              step="0.01"
                              className={styles.inputText7}
                              placeholder="0.00$/Mi"
                              style={{ border: "none" }}
                              value={
                                prepaidMileage !== undefined
                                  ? prepaidMileage
                                  : idToUpdate?.rentPrepaidMileage || ""
                              }
                              onChange={handlePrepaidMileage}
                              maxLength={25}
                              pattern="[0-9]*[.,]?[0-9]+" // Allows floating point numbers
                              inputMode="decimal" // Allows decimal input
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.frameChild} />
              <div className={styles.inputWithLabelContainer}>
                <div className={styles.inputWithLabel3}>
                  <div className={styles.inputText8}>For lease</div>
                  <div className={styles.inputTextGroup}>
                    <div className={styles.inputText9}>
                      You can rent the truck based on the stipulated period.
                    </div>
                    <div
                      className={styles.toggle}
                      style={{
                        border:
                          requiredField == "enableForLease"
                            ? "1px solid red"
                            : "",
                      }}
                    >
                      <input
                        className={styles.checkbox2}
                        type="checkbox"
                        style={{
                          height: "inherit",
                          width: "100%",
                          border: "0",
                          boxShadow: "0px 0px 3px inset",
                        }}
                        onChange={handleEnableForLease}
                        checked={
                          enableForLease ||
                          (idToUpdate?.enableForLease && enableForLease)
                        }
                      />
                      <div className={styles.on}>On</div>
                    </div>
                  </div>
                </div>
                {(enableForLease ||
                  (idToUpdate?.enableForLease && enableForLease)) && (
                  <>
                    <div className={styles.frameDiv}>
                      <div className={styles.inputWithLabel4}>
                        <div className={styles.inputText10}>
                          Cost per rent per day
                        </div>
                        <div
                          className={styles.basicInput2}
                          style={{
                            border:
                              requiredField == "leaseCostRentPerDay"
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <div className={styles.inputLeftContent2}>
                            <img
                              className={styles.iconoutlinecurrencyDollar2}
                              alt=""
                              src="/generics/iconoutlinecurrencydollar.svg"
                            />
                            <input
                              type="text"
                              className={styles.inputText11}
                              placeholder="Cost per rent per day"
                              style={{ border: "none" }}
                              value={
                                leaseCostRentPerDay ||
                                (idToUpdate?.leasePerDay &&
                                isNaN(Number(leaseCostRentPerDay))
                                  ? idToUpdate?.leasePerDay
                                  : "")
                              }
                              onChange={handleLeaseCostPerDay}
                              maxLength={25}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.inputWithLabel5}>
                        <div className={styles.inputText12}>
                          Prepaid Mileage
                        </div>
                        <div
                          className={styles.basicInput3}
                          style={{
                            border:
                              requiredField == "leasePrepaidMileage"
                                ? "1px solid red"
                                : "",
                          }}
                        >
                          <div className={styles.inputLeftContent3}>
                            <img
                              className={styles.iconoutlinecurrencyDollar3}
                              alt=""
                              src="/generics/iconoutlinecurrencydollar.svg"
                            />
                            <input
                              type="number"
                              step="0.01"
                              className={styles.inputText13}
                              placeholder="0.00$/Mi"
                              style={{ border: "none" }}
                              value={
                                leasePrepaidMileage !== undefined
                                  ? leasePrepaidMileage
                                  : idToUpdate?.leasePrepaidMileage || ""
                              }
                              onChange={handleLeaseMileage}
                              maxLength={25}
                              pattern="[0-9]*[.,]?[0-9]+" // Allows floating point numbers
                              inputMode="decimal"

                              ///
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.frameItem} />
              {currentUser.role == "renter" ? (
                <></>
              ) : (
                <>
                  <div className={styles.inputWithLabel6}>
                    <div className={styles.inputText14}>Deposite</div>
                    <div
                      className={styles.basicInput4}
                      style={{
                        border:
                          requiredField == "deposite" ? "1px solid red" : "",
                      }}
                    >
                      <div className={styles.inputLeftContent4}>
                        <img
                          className={styles.iconoutlinecurrencyDollar4}
                          alt=""
                          src="/generics/iconoutlinecurrencydollar.svg"
                        />
                        <input
                          type="text"
                          className={styles.inputText15}
                          placeholder="Deposite"
                          style={{ border: "none" }}
                          value={
                            deposite ||
                            (idToUpdate?.deposit && isNaN(Number(deposite))
                              ? idToUpdate?.deposit
                              : "")
                          }
                          onChange={handleDeposite}
                          maxLength={25}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameInner} />
                  <div className={styles.inputWithLabelParent1}>
                    <div className={styles.inputWithLabel7}>
                      <div className={styles.inputText16}>Equipment Price</div>
                      <div
                        className={styles.basicInput5}
                        style={{
                          border:
                            requiredField == "equipementPrice"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <div className={styles.inputLeftContent5}>
                          <img
                            className={styles.iconoutlinecurrencyDollar5}
                            alt=""
                            src="/generics/iconoutlinecurrencydollar.svg"
                          />
                          <input
                            type="text"
                            className={styles.inputText17}
                            placeholder="Equipment Price"
                            style={{ border: "none" }}
                            value={
                              equipementPrice ||
                              (idToUpdate?.equipmentPrice &&
                              isNaN(Number(equipementPrice))
                                ? idToUpdate?.equipmentPrice
                                : "")
                            }
                            onChange={handleEquipementPrice}
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputWithLabel8}>
                      <div className={styles.inputText18}>Insurance Price</div>
                      <div
                        className={styles.basicInput6}
                        style={{
                          border:
                            requiredField == "insurrancePrice"
                              ? "1px solid red"
                              : "",
                        }}
                      >
                        <div className={styles.inputLeftContent6}>
                          <img
                            className={styles.iconoutlinecurrencyDollar6}
                            alt=""
                            src="/generics/iconoutlinecurrencydollar.svg"
                          />
                          <input
                            type="text"
                            className={styles.inputText19}
                            placeholder="Insurance Price"
                            style={{ border: "none" }}
                            value={
                              insurrancePrice ||
                              (idToUpdate?.insurancePrice &&
                              isNaN(Number(insurrancePrice))
                                ? idToUpdate?.insurancePrice
                                : "")
                            }
                            onChange={handleInsurrancePrice}
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputWithLabel9}>
                      <div className={styles.inputText20}>Taxes</div>
                      <div
                        className={styles.basicInput7}
                        style={{
                          border:
                            requiredField == "taxes" ? "1px solid red" : "",
                        }}
                      >
                        <div className={styles.inputLeftContent7}>
                          <img
                            className={styles.iconoutlinecurrencyDollar7}
                            alt=""
                            src="/generics/iconoutlinecurrencydollar.svg"
                          />
                          <input
                            type="text"
                            className={styles.inputText21}
                            placeholder="Taxes"
                            style={{ border: "none" }}
                            value={
                              taxes ||
                              (idToUpdate?.taxes && isNaN(Number(taxes))
                                ? idToUpdate?.taxes
                                : "")
                            }
                            onChange={handleTaxes}
                            maxLength={25}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.button4}>
                <div className={styles.text4}>Save as a draft</div>
              </div>
              <div className={styles.buttonParent1}>
                <div
                  className={`${styles.button5} hoverButtons`}
                  onClick={onBackButtonClick}
                >
                  <div className={styles.text5}>Back</div>
                </div>
                <div
                  className={`${styles.button6} hoverButtons`}
                  onClick={handleNextClick}
                >
                  <div className={styles.text6}>Finish</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connectorAddTruckPrice(AddTruckPrice);
