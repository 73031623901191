import React from 'react'
import styles from "./ProductPage.module.css";

const CheckBox = (props :any) => {
  return (
    <div>
      {
        props.checked ? (
          <div className={styles.checkbox}>
          <img
            className={styles.checkboxIcon}
            alt=""
            src="/truck/checkbox.svg"
          />
          <div className={styles.textDescription}>{props.text}</div>
        </div>
        ):(
          <div className={styles.checkbox1}>
            <div className={styles.checkbox2} />
            <div className={styles.textDescription2}>{props.text}</div>
          </div>
        )
      }
    </div>
  )
}

export default CheckBox
