import React, { useState, FunctionComponent } from "react";
import styles from '../../../css/Registration_Driver/AddOrganizationList.module.css';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { options } from './OptionValues';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { successToast, errorToast } from "../../../components/Toast/ToastMessage";
import Loader from '../../../components/Loader/Loader';
import { getAuthHeaders, getNoAuthHeaders } from "../../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL, REACT_APP_AUTH_SVC_API_BASE_URL, REACT_APP_ORG_API_BASE_URL } from '../../../utils/UrlConstants';

interface AddOrganizationModalProps {
  lgOrgShow: boolean;
  setLgOrgShow: (value: boolean) => void;
}

interface Company {
  name: string;
  physicalAddress?: {
    street: string;
  };
}

const AddOrganizationModal: FunctionComponent<AddOrganizationModalProps> = ({
  lgOrgShow,
  setLgOrgShow,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [requiredField, setRequiredField] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [showOriginalContent, setShowOriginalContent] = useState<boolean>(true);
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCompaniesOriginal, setDataCompaniesOriginal] = useState<Company[]>([]);
  const [dataCompanies, setDataCompanies] = useState<Company[] | any>(dataCompaniesOriginal);

  const fetchData = async (search: string) => {
    try {
      const apiUrl = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/files/fmcsa/search?search=${search || companyName}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: getNoAuthHeaders(),
      });

      if (!response.ok) {
        console.error("failed", JSON.stringify(response));
        return;
      }
      
      const data = await response.json();
      setDataCompaniesOriginal(Array.from(data));
      setDataCompanies(Array.from(data));

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsDropdownOpen(true);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setInputValue(option);
  };

  const handleAddItClick = () => {
    setShowOriginalContent(false);
  };

  const handleCompanyNameChange = (
    input: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    let inputValue: string;
    if (typeof input === "string") {
      inputValue = input;
    } else {
      inputValue = input.target.value;
    }
    setCompanyName(inputValue);
    fetchData(inputValue);
    if (inputValue === "") {
      setDataCompanies(dataCompaniesOriginal);
    } else {
      const filteredCompanies = dataCompaniesOriginal.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      setDataCompanies(filteredCompanies);
    }
  };

  const handleAccordionClick = async (
    name: string,
    address: string,
    companyEmail: string,
    phone: string,
    dot: string,
    item: any
  ) => {
    setLoading(true);
    const updatedOrganization = {
      "organizations": [
        {
          "dotNumber": dot,
          "name": name
        },
      ],
    }
    const result = await createOrganization(item);
    if(result){
      try {
        const url = `${REACT_APP_AUTH_SVC_API_BASE_URL}/me/update`;
        const response :any = await axios.put(url, updatedOrganization, {
          headers: getAuthHeaders(),
        });
        if (response.status === 200) {
          localStorage.setItem('currentUserUpdated', 'true')
          successToast("Organization updated successfully!")
          setLoading(false);
          setLgOrgShow(false);
        } else {
          errorToast("Error while updating organization!")
          setLoading(false);
        }
      } catch (error) {
        errorToast("Error while updating organization!")
        setLoading(false);
      }
    }else{
      setLoading(false)
    }
  };

  const createOrganization = async (requestBody :any) => {
    const apiUrl = `${REACT_APP_ORG_API_BASE_URL}/organization/create`;
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: getNoAuthHeaders(),
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();

      if (!response.ok) {
        const errorMessage = data.error || "Please select a valid organization";
        errorToast(errorMessage);
        setCompanyName("");
        return false;
      }  
      
      return data;
    } catch (error) {
      errorToast(`${error}`);
      throw error;
    }
  };

  const handleNextButtonClick = async () => {
    try {
    } catch (error) {
    }
  };

  return (
  <div>
    <Modal
      className={`${styles.updatePasswordModal} password-modal`}
      size="lg"
      show={lgOrgShow}
      onHide={() => setLgOrgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <b className={styles.modalTitle}>Update Organization</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
        <div className={styles.textButtonGroup}>
            <div className={styles.textButton1}>
              <img className={styles.icon} alt="" src="/generics/icon6.svg" />
              <div className={styles.reefer}>Back&nbsp;</div>
            </div>
            <div className={styles.inputWithLabelParent}>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>State</div>
                <div className={styles.dropdown}>
                  <div className={styles.inputField} style={{ textAlign: "left" }}>
                    <input
                      type="text"
                      style={{ paddingLeft: 0 }}
                      placeholder="Select an option"
                      value={inputValue}
                      onChange={handleInputChange}
                      onClick={toggleDropdown}
                    />

                    <StyledButton
                      className={styles.dropdownButton}
                      style={{ top: "15%" }}
                      onKeyDown={() => {}}
                      role="button"
                      onClick={toggleDropdown}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </StyledButton>
                    {isDropdownOpen && (
                      <div
                        className={styles.dropdownMenu}
                        style={{
                          background: "#F1F5F9",
                          maxHeight: "200px",
                          overflow: "auto",
                        }}
                      >
                        <Container>
                          {filteredOptions.map((option, index) => (
                            <Item
                              style={{ textAlign: "left" }}
                              className="hoverDropdown"
                              onClick={() => handleOptionSelect(option)}
                              key={index}
                            >
                              {option}
                            </Item>
                          ))}
                        </Container>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.inputWithLabel}>
                <div className={styles.reefer}>Company name&nbsp;</div>
                <div
                  className={styles.basicInput}
                  style={
                    requiredField === "companyName"
                      ? { border: "1px solid red" }
                      : {}
                  }
                >
                  <input
                    type="text"
                    className={styles.inputField}
                    value={companyName}
                    placeholder="Company name"
                    style={{ border: "none", paddingLeft: "0" }}
                    onChange={handleCompanyNameChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.frameChild} />

            <div
              className={`${styles.button1} hoverButtons`}
              onKeyDown={() => {}}
              role="button"
              onClick={() => handleNextButtonClick()}
            >
              <div className={styles.text}>Search company</div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText8}>Length</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Small</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Large</div>
                </div>
              </div>
            </div>
            <div className={styles.inputTextContainer}>
              <div className={styles.inputText8}>Lift Gate</div>
              <div className={styles.tagParent}>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Rail Gate</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>Tuck Under</div>
                </div>
                <div className={styles.tag}>
                  <img
                    className={styles.earnRevenueFromYourIdleFl}
                    alt=""
                    src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
                  />
                  <div className={styles.reefer}>No Lift Gate</div>
                </div>
              </div>
            </div>
            <div className={styles.resultsFoundParent}>
              <div className={styles.resultsFound}>
                {dataCompanies.length} results found
              </div>
              <div
                className={styles.companyNotOnTheListParent}
                onKeyDown={() => {}}
                role="button"
                onClick={handleAddItClick}
              >
                <div className={styles.resultsFound}>Company not on the list?</div>
                <div className={styles.addIt}>+Add it</div>
              </div>
            </div>
            <div className={styles.frameParent}>
              <div className={styles.accordionParent} id={"listItems"}>
                {
                  loading ? (
                    <>
                      <Loader />
                    </>
                  ):(
                    <>
                      {dataCompanies.map((item: any) => {
                        return (
                          <div
                            key={item}
                            className={styles.accordion}
                            role="button"
                            onClick={() =>
                              handleAccordionClick(
                                item.name,
                                item.physicalAddress?.street,
                                item.email,
                                item.phone,
                                item.dotNumber,
                                item
                              )
                            }
                          >
                            <div className={styles.headlineParent}>
                              <div className={styles.headline}>{item.name}</div>
                            </div>
                            <div
                              className={styles.inputText1}
                              style={{ textAlign: "left" }}
                            >
                              {item.physicalAddress?.street}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default AddOrganizationModal;

const StyledButton = styled.button`
  position: absolute;
  border: 0;
  right: 0;
  background-color: transparent;
`;

const Container = styled.div`
  border-radius: 8px;
  box-shadow: 0px 10px 22px 0px rgba(45, 77, 108, 0.15);
  background-color: var(--basic-white, #fff);
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  color: var(--neutral-900, #0f172a);
  white-space: nowrap;
  align-items: start;
  border-radius: 8px;
  background-color: var(--basic-white, #fff);
  width: 100%;
  justify-content: center;
  padding: 8px 60px 8px 16px;
  font: 400 14px/20px Inter, sans-serif;

  @media (max-width: 991px) {
    white-space: initial;
    padding-right: 20px;
  }
`;
