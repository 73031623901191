import React, { useCallback, useState } from "react";
import styles from "../css/Registration_Owner/RegistrationOwnerFinal.module.css";

import Select from "react-select";
import { InputActionMeta } from "react-select";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import RegisterationSideImage1 from "../components/Registration/RegisterationSideImage1";

type VechicleToRentProps = {
  onNextButtonClick?: () => void;
  onBackButtonClick: () => void;
  onNextButtonKeyDown: () => void;
  role: string;
  onClick?: () => void;
};

const RegistrationOwnerFinal: React.FC<VechicleToRentProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const onInputWithLabelClick = useCallback(() => {}, []);

  const handleNextClick = () => {
    onNextButtonClick?.();
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  return (
    <div className={styles.vehicleOwner07}>
      <RegisterationSideImage1 styles={styles} />

      <div className={styles.vehicleOwner07Child} />
      <div className={styles.modal}>
        <div
          className={styles.textButton}
          onClick={handleBackButtonClick}
          style={{ cursor: "pointer" }}
        >
          <img className={styles.icon} alt="" src="/generics/icon2.svg" />
          <div className={styles.reefer}>Back&nbsp;</div>
        </div>
        <div className={styles.iconParent}>
          <img
            className={styles.icon1}
            alt=""
            src="/generics/iconsolidcheckcircle.svg"
          />
          <div className={styles.userName}>
            Thank you for successfully registering on our website! One more step
          </div>
        </div>
        <div className={styles.inputTextContainer}>
          <div className={styles.inputText3}>
            To verify and publish the truck, you need to fill out a few more
            forms.
          </div>
          <div className={styles.inputText4}>Redirecting in 11 sec</div>
        </div>
        <div className={styles.buttonParent}>
          <div className={`${styles.button1} hoverButtons`}>
            <Link to="/main" style={{ color: "inherit" }}>
              <div className={styles.text}>I'll do it later</div>
            </Link>
          </div>
          <div className={`${styles.button2} hoverButtons`}>
            <div
              className={styles.text}
              onKeyDown={() => {}}
              role="button"
              onClick={handleNextClick}
            >
              Continue Verification
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.inputField}>Length</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Small</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Large</div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.inputField}>Lift Gate</div>
          <div className={styles.tagParent}>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Rail Gate</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>Tuck Under</div>
            </div>
            <div className={styles.tag}>
              <img
                className={styles.earnRevenueFromYourIdleFl}
                alt=""
                src="/generics/earn-revenue-from-your-idle-fleet--list-your-truck-on-coop-1@2x.png"
              />
              <div className={styles.reefer}>No Lift Gate</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOwnerFinal;
