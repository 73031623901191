import { FunctionComponent, useState, useEffect, useRef } from "react";
import styles from "./css/AddTruckExpirationData.module.css";
import { updateTruckRegistrationData } from "../../store/actions/registrationActions";
import { TruckRegistrationData, RootStateTruckAdd } from "../../store/types";
import { connect } from "react-redux";
import { PropsFromReduxAddTruckToRent } from "./AddTruckApplyToRent";
import { errorToast, successToast } from "../../components/Toast/ToastMessage";
import { getAuthHeaders } from "../../utils/GetHeaders";
import { REACT_APP_UPLOAD_API_BASE_URL } from '../../utils/UrlConstants';

export type AddTruckExpirationDate = {
  handleUpdateTruckRegistrationData: (data: TruckRegistrationData) => void;
  truckRegData: TruckRegistrationData;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  setFileNames: (value: any) => void;
  setDescription: (value: string) => void;
  description: string;
  fileNames: any;
  idToUpdate?: TruckRegistrationData;
} & PropsFromReduxAddTruckToRent;

type SelectedImages = {
  threeQuarterViewImage: File | null;
  frontImage: File | null;
  passengerSideImage: File | null;
  backImage: File | null;
};

const mapDispatchToProps = {
  updateTruckRegistrationData,
};

const mapStateToProps = (state: RootStateTruckAdd) => ({
  truckAddRegistrationData: state.truckRegistrationData,
});

const connectorAddTruckExpirationDate = connect(
  mapStateToProps,
  mapDispatchToProps
);

const AddTruckExpirationDate: FunctionComponent<AddTruckExpirationDate> = ({
  onNextButtonClick,
  truckAddRegistrationData,
  updateTruckRegistrationData,
  onBackButtonClick,
  setFileNames,
  setDescription,
  description,
  fileNames,
  idToUpdate,
}) => {
  const threeQuarterViewImage = useRef<HTMLInputElement | null>(null);
  const frontImage = useRef<HTMLInputElement | null>(null);
  const passengerSideImage = useRef<HTMLInputElement | null>(null);
  const backImage = useRef<HTMLInputElement | null>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [fileChanged, setFileChanged] = useState<string>("");
  const [requiredField, setrequiredField] = useState<string>("");

  const [selectedImages, setSelectedImages] = useState<SelectedImages>({
    threeQuarterViewImage: null,
    frontImage: null,
    passengerSideImage: null,
    backImage: null,
  });

  const handleDivClick = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    fetchAndSetImages();
  }, []);

  useEffect(() => {
    const singleImageUpload = async () => {
      const formData = new FormData();

      Object.entries(selectedImages).forEach(([fieldName, file]) => {
        if (file) {
          console.log(file);
          formData.append(fieldName, file);
        }
      });

      try {
        console.log(idToUpdate);
        console.log("Selected vvv");
        console.log(selectedImages);
        const url = `${REACT_APP_UPLOAD_API_BASE_URL}/upload/truck/images`;
        const method = "POST";
        const response = await fetch(url, {
          method: method,
          headers: getAuthHeaders(),
          body: formData,
        }).then((r) => r.json());

        console.log(formData);
        console.log("Response vvv");
        console.log(response);

        if (response.fileLinks) {
          const updatedRegistrationData: Partial<TruckRegistrationData> = {
            ...truckAddRegistrationData,
            images: {
              ...(truckAddRegistrationData?.images || {}),
              back: response.fileLinks.backImage,
              front: response.fileLinks.frontImage,
              passengerSide: response.fileLinks.passengerSideImage,
              threeQuarterView: response.fileLinks.threeQuarterViewImage,
            },
          };
          updateTruckRegistrationData(updatedRegistrationData);
          successToast("Image uploaded successfully");
        } else {
          setFileNames((prevState: any) => ({
            ...prevState,
            [fileChanged]: "",
          }));
          clearRefValue(fileChanged);
          errorToast(`Error: ${response.error}`);
        }
      } catch (error: any) {
        errorToast("Error uploading image");
        console.error("Error uploading image:", error);
      }
    };

    if (isFileChanged) singleImageUpload();
    setIsFileChanged(false);
  }, [selectedImages, isFileChanged, fileChanged]);

  const fetchAndSetImages = async () => {
    const threeQuarterViewFile = idToUpdate?.images.threeQuarterView
      ? await urlToFile(idToUpdate.images.threeQuarterView)
      : null;

    setSelectedImages({
      threeQuarterViewImage: threeQuarterViewFile,
      frontImage: null,
      passengerSideImage: null,
      backImage: null,
    });
  };

  const urlToFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const mimeType = response.headers.get("content-type") || undefined;
      const filename = url.split("/").pop() || "image.png";
      const blob = await response.blob();
      const file = new File([blob], filename, { type: mimeType });
      console.log(file);
      return file;
    } catch (error) {
      console.error("Error converting URL to File:", error);
      return null;
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    const inputValue = event.target.value;

    const updatedRegistrationData: Partial<TruckRegistrationData> = {
      ...truckAddRegistrationData,
      description: inputValue,
    };

    updateTruckRegistrationData(updatedRegistrationData);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof SelectedImages
  ) => {
    const file = event.target.files?.[0];

    if (!file) return;

    setIsFileChanged(true);
    setSelectedImages((prevState) => ({
      ...prevState,
      [fieldName]: file,
    }));

    setFileNames((prevState: any) => ({
      ...prevState,
      [fieldName]: file.name,
    }));
    setFileChanged(fieldName);
  };

  const handleNextClick = async () => {
    console.log(fileNames["threeQuarterViewImage"]);
    if (fileNames["threeQuarterViewImage"] == undefined && !idToUpdate) {
      setrequiredField("threeQuarterImage");
      errorToast("Three quarter image is required");
      return;
    }
    onNextButtonClick();
  };

  const clearRefValue = (refName: string) => {
    switch (refName) {
      case "threeQuarterViewImage":
        if (threeQuarterViewImage.current)
          threeQuarterViewImage.current.value = "";
        break;
      case "frontImage":
        if (frontImage.current) frontImage.current.value = "";
        break;
      case "passengerSideImage":
        if (passengerSideImage.current) passengerSideImage.current.value = "";
        break;
      case "backImage":
        if (backImage.current) backImage.current.value = "";
        break;
      default:
        return;
    }
  };

  return (
    <>
      <div className={styles.registerATruckOrEdit4P}>
        <div className={styles.registerATruckOrEdit4PChild} />
        <main className={styles.parentFrame}>
          <div className={styles.parentFrameInner}>
            <div className={styles.frameParent}>
              <div className={styles.textButtonParent}>
                <div className={styles.textButton} onClick={onBackButtonClick}>
                  <img
                    className={styles.icon}
                    loading="eager"
                    alt=""
                    src="/generics/icon.svg"
                  />
                  <div className={styles.textbuttonlabel}>Back</div>
                </div>
                <div className={styles.inputText}>4 of 6</div>
              </div>
              <div className={styles.rectangleWrapper}>
                <div className={styles.frameChild} />
              </div>
            </div>
          </div>
          <div className={styles.parentFrameChild}>
            <div className={styles.userNameParent}>
              <h2
                className={styles.userName}
              >&nbsp;Registration expiration date&nbsp;</h2>
              <div
                className={styles.inputText1}
              >The 3/4 view (frontImage & side) photo is required, including both the frontImage and side views, is mandatory to appeal to a larger number of potential renters.</div>
            </div>
          </div>
          <section className={styles.frameSection}>
            {/* {2 } */}
            <div className={styles.textButtonGroup}>
              <div className={styles.inputTextParent}>
                <div
                  className={styles.inputText2}
                  style={{
                    color: requiredField == "threeQuarterImage" ? "red" : "",
                  }}
                >
                  3/4 view*
                </div>
                <div
                  className={`${styles.addImage}  hoverButtons`}
                  onClick={() => handleDivClick(threeQuarterViewImage)}
                >
                  <img
                    src="/add_truck/3_4pic.png"
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                    // onClick={() => handleDivClick(threeQuarterViewImage)}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput-threeQuarterViewImage"
                      ref={threeQuarterViewImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "threeQuarterViewImage")
                      }
                    />

                    <div className={styles.iconoutlinedocumentParent}>
                      <img
                        className={styles.iconoutlinedocument}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageParent}>
                        {fileNames["threeQuarterViewImage"] ? ( 
                          <div className={styles.fileName}>
                            {fileNames["threeQuarterViewImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.threeQuarterView
                                ? idToUpdate?.images.threeQuarterView
                                    .split("/")
                                    .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.inputTextGroup}>
                <div className={styles.inputText2}>Front image of vehicle</div>
                <div
                  className={`${styles.addImage2} hoverButtons`}
                  onClick={() => handleDivClick(frontImage)}
                >
                  <img
                    src="/add_truck/Front1.png"
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput-frontImage"
                      ref={frontImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "frontImage")
                      }
                    />
                    <div className={styles.iconoutlinedocumentGroup}>
                      <img
                        className={styles.iconoutlinedocument1}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageGroup}>
                        {fileNames["frontImage"] ? ( 
                          <div className={styles.fileName}>
                            {fileNames["frontImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.front
                                ? idToUpdate?.images.front.split("/").pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.inputTextContainer}>
                <div className={styles.inputText2}>
                  Passenger side of vehicle
                </div>
                <div
                  className={`${styles.addImage4}  hoverButtons`}
                  onClick={() => handleDivClick(passengerSideImage)}
                >
                  <img
                    src="/add_truck/Passenger_Side.png"
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput-passengerSideImage"
                      ref={passengerSideImage}
                      style={{ display: "unset" }}
                      onChange={(event) =>
                        handleFileChange(event, "passengerSideImage")
                      }
                    />
                    <div className={styles.iconoutlinedocumentContainer}>
                      <img
                        className={styles.iconoutlinedocument2}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageContainer}>
                        {fileNames["passengerSideImage"] ? (
                          <div className={styles.fileName}>
                            {fileNames["passengerSideImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.passengerSide
                                ? idToUpdate?.images.passengerSide
                                    .split("/")
                                    .pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className={styles.frameDiv}>
                <div className={styles.inputText2}>Back Image</div>
                <div
                  className={`${styles.addImage6} hoverButtons`}
                  onClick={() => handleDivClick(backImage)}
                >
                  <img
                    src="/add_truck/Back.png"
                    loading="eager"
                    alt="3/4"
                    className={styles.docsAddImages}
                  />
                  <label
                    htmlFor="fileInput"
                    className={styles.fileInputLabel}
                    style={{ width: "100%" }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="fileInput-backImage"
                      ref={backImage}
                      style={{ display: "unset" }}
                      onChange={(event) => handleFileChange(event, "backImage")}
                    />
                    <div className={styles.iconoutlinedocumentParent1}>
                      <img
                        className={styles.iconoutlinedocument3}
                        loading="eager"
                        alt=""
                        src="/generics/iconoutlinedocument1.svg"
                      />
                      <div className={styles.addImageParent1}>
                        {fileNames["backImage"] ? ( 
                          <div className={styles.fileName}>
                            {fileNames["backImage"]}
                          </div>
                        ) : (
                          <>
                            <div className={styles.addImage1}>Add image</div>
                            <div className={styles.dragAndDrop}>
                              {idToUpdate?.images.back
                                ? idToUpdate?.images.back.split("/").pop()
                                : `Click to select file`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </section>
          <div
            className={styles.inputWithLabel}
            style={{ width: "60%", margin: "auto" }}
          >
            <div className={styles.inputText6}>Description*</div>
            <div className={styles.textareaInput}>
              <textarea
                style={{ width: "100%", height: "100%", border: "0" }}
                className={styles.inputText7}
                placeholder="Placeholder"
                value={description || idToUpdate?.description}
                onChange={handleDescriptionChange}
              />
            </div>
          </div>
        </main>
        <div className={styles.inputFieldPair}>
          <div className={styles.buttonContainer}>
            <button className={styles.button4}>
              <div className={styles.textContent}>Save as a draft</div>
            </button>
            <div className={styles.twoButtonsFrame}>
              <button className={styles.button5}>
                <div
                  className={styles.inputFieldsFrame}
                  onClick={onBackButtonClick}
                >
                  Back
                </div>
              </button>
              <button className={styles.button6} onClick={handleNextClick}>
                <div className={styles.text3}>Next</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connectorAddTruckExpirationDate(AddTruckExpirationDate);
