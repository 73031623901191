import {
  SET_ORGANIZATION,
} from "./actionTypes";
import { Organization } from "../types";

type PartialOrganization = Partial<Organization>;

export const setOrganization = (
  data: PartialOrganization,
) => ({
  type: SET_ORGANIZATION,
  data,
});
