import { FunctionComponent, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../pages/RegistrationInitialOwner.module.css";

const SideAndNavbarOwner: FunctionComponent = () => {
  const navigate = useNavigate();

  const onTheLogoMakerBuiltFor1000Click = useCallback(() => {}, []);

  const onRadioGroupItemClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleClick = useCallback(() => {}, []);

  return (
    <>
      <div className={styles.unsplashx60ygfg5soyParent} style={{ zIndex: "3" }}>
        <img
          className={styles.unsplashx60ygfg5soyIcon}
          style={{ zIndex: "3" }}
          alt=""
          src="/generics/unsplashx60ygfg5soy@2x.png"
        />

        <div className={styles.inputTextParent} style={{ zIndex: "4" }}>
          <div className={styles.inputText}>One place to manage everything</div>
          <div className={styles.inputText1}>
            We'll handle your worldwide compliance, payroll, and benefits, so
            you can fast-track your international expansion.
          </div>
        </div>
        <img
          className={styles.celesClubPVidiGruzovikhMaIcon}
          alt=""
          src="/generics/1654887482-34celesclubpvidigruzovikhmashinmashinikrasivofoto41removebgpreview@2x.png"
        />
      </div>
      <div className={styles.alreadyHaveAccount}>
        {/* <Link to="/"> */}
        <img
          className={styles.theLogoMakerBuiltFor1000}
          style={{ zIndex: "3" }}
          alt=""
          src="/logos/Truck4 logo-1.png"
          onKeyDown={() => {}}
          role="button"
          onClick={() => {
            window.location.href = "/#/main"; //sorry for this, the png start jumping if I do it via <Link>
          }}
        />
        {/* </Link> */}
        <div className={styles.inputTextGroup}>
          <div className={styles.inputText2}>Already have an account?</div>
          <Link to="/login">
            <div className={styles.button}>
              <div className={styles.text}>Log In</div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SideAndNavbarOwner;
