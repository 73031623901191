export const plateStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const truckEngines = [
  "Cummins",
  "Caterpillar",
  "Detroit Diesel",
  "International",
  "Volvo",
  "Mack",
  "Mercedes-Benz",
  "Paccar",
  "Isuzu",
  "Hino",
  "Scania",
  "MAN",
  "Perkins",
  "Navistar",
  "Deutz",
  "Fuso",
  "UD Trucks",
  "Tata Motors",
  "Cummins Westport",
  "Yanmar",
  "John Deere",
  "Duramax",
  "Power Stroke",
  "MaxxForce",
  "DD13",
  "DD15",
  "DD16",
  "MP7",
  "MP8",
  "MP10",
  "MX-13",
  "X12",
  "X15",
];

export const subtypeValues = [
  "lowboy semi-trailer",
  "drop deck semi-trailer",
  "box trucks dry Van trucks / straight trucks moving cutaway-cube",
  "flatbed semi-trailer -48ft -53ft",
  "dry van semi-trailer",
  "hot shot trucks mounted un-mounted",
  "other trucks",
  "other trailers",
  "expeditor trucks",
  "car hauler trucks",
  "reefer / refrigerated trucks",
  "flatbed trucks",
  "sleeper trucks",
  "glider kit trucks",
  "day cab trucks",
  "car carrier trailers semi-trailers -enclosed -open",
  "reefer semi-trailer",
  "beverage trucks mounted un-mounted",
  "trailer",
  "truck",
];

export const subtypeTruckValues = [];

export const subtypeTrailerValues = [];

export const truckManufacturers = [
  "Volvo",
  "Kenworth",
  "Peterbilt",
  "Mack",
  "Freightliner",
  "International",
  "Scania",
  "MAN",
  "Western Star",
  "Isuzu",
  "Hino",
  "Ford",
  "Chevrolet",
  "GMC",
  "Ram",
  "Mercedes-Benz",
  "Iveco",
  "DAF",
  "Renault",
  "Fuso",
  "UD Trucks",
  "Tata Motors",
  "Ashok Leyland",
  "Mitsubishi",
  "Navistar",
  "Paccar",
  "Daimler",
  "MAN",
  "UD Trucks",
  "Sinotruk",
  "JAC Motors",
  "Foton",
  "Shacman",
  "Howo",
];

export const lengthOptions = ["small", "large"];

export const cargoOptions = ["rail-gate", "truck-under", "no-lift-gate"];
